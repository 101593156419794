import { takeEvery, put, getContext, select, fork } from "redux-saga/effects";
import {
  createRequestInstance,
  watchRequests
  // success
} from "redux-saga-requests";
import { createDriver } from "redux-saga-requests-axios";

import {
  requestSession,
  logout,
  setServerError,
  setAccessToken
} from "../store/actions";
import * as auth from "../library/auth";

export function* handleSession() {
  yield put(requestSession());
}

export function* onErrorSaga(error) {
  if (error.response.status === 401) {
    const token = yield select(state => state.app["access-token"]);

    if (token) {
      yield put(logout());
    }
  }

  if (error.response.status >= 500) {
    yield put(setServerError(error));
  }

  return { error };
}

export function handleLogout() {
  auth.logout();
}

export default function* requestsSaga() {
  const axios = yield getContext("axios");

  yield createRequestInstance({
    driver: createDriver(axios),
    onError: onErrorSaga
  });

  yield fork(watchRequests);

  yield takeEvery(setAccessToken, handleSession);
  yield takeEvery(logout, handleLogout);
}
