import { useEffect, useState, useCallback, useMemo } from "react";

export function useFreshState(newVal) {
  const [val, setVal] = useState(newVal);
  useEffect(() => {
    setVal(newVal);
  }, [newVal]);

  return [val, setVal];
}

export function useToggle(enabled) {
  const [isEnabled, setToggle] = useState(enabled);

  const toggle = useCallback(() => setToggle(enabled => !enabled), [setToggle]);

  const disable = useCallback(() => setToggle(false), [setToggle]);
  const enable = useCallback(() => setToggle(true), [setToggle]);

  return useMemo(
    () => ({
      isEnabled,
      toggle,
      disable,
      enable
    }),
    [isEnabled, toggle, disable, enable]
  );
}

/**
 * Syntax sugar for not eslint-disable-next-line react-hooks/exhaustive-deps all the time
 */
export function useEffectOnce(effect) {
  useEffect(() => {
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
