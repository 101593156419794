import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as s from "./ColumnHeadline.module.css";

ColumnHeadline.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  subtitle: PropTypes.string,
  uppercase: PropTypes.bool
};

export default function ColumnHeadline({
  title,
  loading,
  subtitle,
  uppercase = true
}) {
  return (
    <div className={classNames(s.container)}>
      <h2 className={classNames(s.title, loading && s.loading)}>
        {uppercase ? title.toUpperCase() : title}
      </h2>
    </div>
  );
}
