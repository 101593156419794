import React from "react";
import { Modal } from "./Modal";
import { ActionButton } from "../../components/ui/Button/Button";
import {
  deletePriceList,
} from "../../store/actions";

export function DeletePriceListModal({ dispatch, state: { modal } }) {
  const { priceList } = modal.props;
  return (
    <Modal
      dispatch={dispatch}
      title="Smazání ceníku"
      content={<>
        Opravdu chcete smazat ceník <strong>{priceList?.title}</strong>?
      </>}
      actions={
        <ActionButton
          onClick={() => {
            dispatch(deletePriceList(priceList.id));
          }}
        >
          Smazat ceník
        </ActionButton>
      }
    />
  );
}
