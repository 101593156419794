import { useEffect } from "react";

export function useDataRequests(
  { state, dispatch },
  requests = [],
  dependencies = []
) {
  const effect = () => {
    for (let i = 0; i < requests.length; i++) {
      const action = requests[i]();
      if (action) dispatch(action);
    }
    // debugger;
    return () => {
      //
    };
  };

  return useEffect(effect, dependencies);
}
