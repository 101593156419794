import {
  updateMatrixConfigurationValue,
  prepareNewMatrixConfiguration,
  patchMatrixConfigurationLeadingColumn,
  requestMatrixConfigurations,
  submitNewMatrixConfiguration,
  patchMatrixConfiguration,
  updateMatrix,
  deleteMatrix,
  requestFilters,
  requestCountrySettings,
  updateMatrixLeadingValue
} from "../actions";
import { createReducer } from "redux-act";
import { success, error } from "redux-saga-requests";
import * as fp from "lodash/fp";

const DEFAULT_MATRIX_HEIGHT = 4;
const DEFAULT_MATRIX_WIDTH = 4;

export const createMatrix = (
  width = DEFAULT_MATRIX_WIDTH,
  height = DEFAULT_MATRIX_HEIGHT,
  defaultValue = 1
) => {
  const matrix = [];
  for (let i = 0; i < height; i++) {
    for (let j = 0; j < width; j++) {
      if (Array.isArray(matrix[i])) {
        matrix[i][j] = defaultValue;
      } else {
        matrix[i] = [defaultValue];
      }
    }
  }
  return matrix;
};

const createConfigurationDraft = ({
  name,
  countryCode,
  leading = { x: 0, y: 0 }
}) => ({
  id: null,
  name,
  country_code: countryCode,
  leading_x: leading.x,
  leading_y: leading.y,
  matrix: createMatrix(DEFAULT_MATRIX_HEIGHT, DEFAULT_MATRIX_WIDTH),
  isDraft: true
});

export default createReducer(
  {
    [updateMatrixConfigurationValue]: (
      state,
      { configuration, x, y, value }
    ) => {
      const { matrix } = configuration;
      matrix[y][x] = Number(value);
      return {
        ...state,
        localCopy: {
          ...state.localCopy,
          [configuration.country_code]: state.localCopy[
            configuration.country_code
          ].map(x =>
            x.id === configuration.id
              ? { ...configuration, matrix, isModified: true }
              : x
          )
        }
      };
    },
    [updateMatrixLeadingValue]: (
      state,
      { countryCode, leadingValNr, x, y }
    ) => {
      const newState = fp.flow(
        fp.update(
          ["leading_values", leadingValNr],
          coords => {
            if (coords.x === x && coords.y === y) return null;
            return { x, y };
          }
        ),
        fp.update(["leading_values"], leadingValues => {
          const lvs = Object.values(leadingValues)
            .filter(vs => vs !== null)
            .map((vs, i) => [i + 1, vs]);
          return Object.fromEntries(lvs);
        })
      )(state);
      return { ...newState };
    },
    [success(requestMatrixConfigurations)]: (
      state,
      { data },
      { countryCode }
    ) => {
      if (countryCode !== '$__ALL__$') {
        return {
          ...state,
          localCopy: {
            ...state.localCopy,
            [countryCode]: data
          }
        };
      } else {
        const res = {};
        data.forEach(item => {
          res[item.country_code] = res[item.country_code] || [];
          res[item.country_code].push(item);
        });
        return {
          ...state,
          localCopy: res,
        };
      }
    },
    [success(patchMatrixConfigurationLeadingColumn)]: (
      state,
      _,
      { countryCode, x, y }
    ) => {
      const updateLeading = configuration => ({
        ...configuration,
        leading_x: x,
        leading_y: y
      });

      const drafts = state.drafts[countryCode]
        ? {
            ...state.drafts,
            [countryCode]: updateLeading(state.drafts[countryCode])
          }
        : state.drafts;

      return {
        ...state,
        localCopy: {
          ...state.localCopy,
          [countryCode]: state.localCopy[countryCode].map(updateLeading)
        },
        drafts,
        errors: {
          ...state.errors,
          [countryCode]: undefined
        }
      };
    },
    [prepareNewMatrixConfiguration]: (state, { name, countryCode }) => {
      const firstConfiguration = state.localCopy[countryCode][0];
      const draft = createConfigurationDraft({
        name,
        countryCode,
        leading: firstConfiguration
          ? {
              x: firstConfiguration.leading_x,
              y: firstConfiguration.leading_y
            }
          : { x: 0, y: 0 }
      });

      return {
        ...state,
        drafts: {
          ...state.drafts,
          [countryCode]: draft
        }
      };
    },
    [success(submitNewMatrixConfiguration)]: (state, { data }) => {
      return {
        ...state,
        // add to localCopy
        localCopy: {
          ...state.localCopy,
          [data.country_code]: [
            ...(state.localCopy[data.country_code] || []),
            data
          ]
        },
        // remove from drafts
        drafts: {
          ...state.drafts,
          [data.country_code]: undefined
        },
        errors: {
          ...state.errors,
          [data.country_code]: {
            ...(state.errors[data.country_code] || {}),
            draft: undefined
          }
        }
      };
    },
    [error(submitNewMatrixConfiguration)]: (state, error, meta) => {
      return {
        ...state,
        errors: {
          ...state.errors,
          [meta.countryCode]:
            {
              ...state.errors[meta.coutryCode],
              draft: error
            } || undefined
        }
      };
    },
    [success(updateMatrix)]: (state, { data }, meta) => {
      return {
        ...state,
        errors: {
          ...state.errors,
          [meta.countryCode]: {
            ...(state.errors[meta.countryCode] || {}),
            [meta.id]: undefined
          }
        }
      }
    },
    [error(updateMatrix)]: (state, error, meta) => {
      return {
        ...state,
        errors: {
          ...state.errors,
          [meta.countryCode]:
            {
              ...state.errors[meta.coutryCode],
              [meta.id]: error
            } || undefined
        }
      };
    },
    [success(deleteMatrix)]: (state, { data }, meta) => {
      return {
        ...state
      }
    },
    [success(patchMatrixConfiguration)]: (state, { data }, meta) => {
      // data === ""

      return {
        ...state,
        // update local copy
        localCopy: {
          ...state.localCopy,
          [meta.countryCode]: state.localCopy[
            meta.countryCode
          ].map(configuration =>
            configuration.id === meta.id
              ? { ...configuration, name: meta.name, isModified: false }
              : configuration
          )
        },
        errors: {
          ...state.errors,
          [meta.countryCode]: {
            ...(state.errors[meta.countryCode] || {}),
            [meta.id]: undefined
          }
        }
      };
    },
    [error(patchMatrixConfiguration)]: (state, error, meta) => {
      return {
        ...state,
        errors: {
          ...state.errors,
          [meta.countryCode]:
            {
              ...state.errors[meta.coutryCode],
              [meta.id]: error
            } || undefined
        }
      };
    },
    [success(requestFilters)]: (state, { data }) => {
      return {
        ...state,
        filters: data
      };
    },
    [success(requestCountrySettings)]: (state, { data }) => {
      return {
        ...state,
        countrySettings: data
      };
    }
  },
  {
    localCopy: {},
    drafts: {},
    errors: {},
    filters: {}
  }
);
