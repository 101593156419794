import React from "react";
import { closeModal } from "../../store/actions";
import * as s from "./Modal.module.css";
import Text from "../ui/Text/Text";
import Button from "../ui/Button/Button";

export function ConfirmMatrixDelete({ dispatch, state: { modal } }) {
  const { title, content, actions } = modal.props;
  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <div>
      <div className={`flex flex-row items-center ${s.header}`}>
        <div className="flex-1">
          <Text size="1.25rem">{title}</Text>
        </div>
        <Button width="10rem" short secondary onClick={handleCloseModal}>
          Zavřít
        </Button>
      </div>
      <div className={s.content}>
        {content && (
          <>
            {content}
            <hr />
          </>
        )}
        <div className="py-4">{actions}</div>
      </div>
    </div>
  );
}
