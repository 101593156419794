import { useMutation } from "react-query";
import { fetch } from "../library/fetch";

export function useSubmitColumnBatchUpdate({ priceListId, countryCode }) {
  const query = {
    method: "PUT",
    url: `/pricing-list/${priceListId}/country/${countryCode}/pricing/batch`
  };
  return useMutation(payload => {
    const {
      value,
      inPercentages,
      x,
      y,
      original,
      pricingGroupIds,
      urlParams
    } = payload;
    return fetch(query, {
      value: Number(value),
      original,
      x,
      y,
      inPercentages,
      pricingGroupIds,
      urlParams
    });
  });
}
