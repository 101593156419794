import { success, error, abort } from "redux-saga-requests";

/**
 * Official redux-saga-request requestsReducer do not supports transformation
 *  of responses to map accessible by key.
 *
 *  Response: [
 *     { id: 1, name: 'item 1' },
 *     { id: 2, name: 'item 2' }
 *   ]
 *
 *  Result: {
 *    1: { data: { id: 1, name: 'item 1' }, error: false, fetching: false },
 *    2: { data: { id: 2, name: 'item 2' }, error: false, fetching: false  }
 *   }
 *
 * @param {Object} config
 * @returns {Function} reducer
 */
export const requestsMappedByMetaReducer = ({
  actionType,
  metaKeyProp,
  multiple,
  transformData = x => x,
  extraReducer = x => x
}) => (state = {}, action) => {
  // Ignore non requests actions atall
  if (action.type.startsWith(actionType) === false) {
    return extraReducer(state, action);
  }

  if (!metaKeyProp) throw new Error("metaKeyProp has to be defined");
  // console.log({action})

  const keyProp = action.meta[metaKeyProp];
  if (!keyProp) {
    throw new Error("Meta does not contain given keyProp = " + metaKeyProp);
  }

  switch (action.type) {
    case String(actionType): {
      const data = (state[keyProp] || {}).data || (multiple ? [] : null);

      return {
        ...state,
        [keyProp]: {
          data,
          error: false,
          fetching: true
        }
      };
    }
    case success(actionType): {
      return {
        ...state,
        [keyProp]: {
          data: transformData(action.payload.data),
          error: action.payload.error,
          fetching: false
        }
      };
    }

    case error(actionType): {
      return {
        ...state,
        [keyProp]: {
          data: multiple ? [] : null,
          error: action.payload,
          fetching: false
        }
      };
    }

    case abort(actionType): {
      return {
        ...state,
        [keyProp]: {
          ...state[keyProp],
          fetching: false
        }
      };
    }

    default:
      return extraReducer(state, action);
  }
};
