import React, { useContext, useEffect, useState } from "react";
import * as s from "./Configuration.module.css";
import classNames from "classnames";
import Text from "../../components/ui/Text/Text";
import Input from "../../components/ui/Input/Input";
import { ActionButton } from "../../components/ui/Button/Button";
import { Target, Trash2 } from "react-feather";
import { updateMatrix, openModal, deleteMatrix } from "../../store/actions";
import { DispatchContext, ReadOnlyContext } from "../../App";
import { createMatrix } from "../../store/reducers/matrix-configuration-forms";

// 1 = A, 2 = B, ...
export function indexToChar(index) {
  return String.fromCharCode(index + 64);
}

export function translateFromLevel(level) {
  return level.charCodeAt(0) - 64;
}

function updateMatrixLeadingValue({ leadingValues, leadingValNr, x, y }) {
  const { [leadingValNr]: removed, ...rest } = leadingValues;
  return leadingValues[leadingValNr] &&
    leadingValues[leadingValNr].x === x &&
    leadingValues[leadingValNr].y === y
    ? rest
    : {
        ...rest,
        [leadingValNr]: { x, y }
      };
}

function Matrix({
  configuration,
  dispatch,
  index,
  configurationError,
  configurationErrors,
  readOnly
}) {
  const [config, setConfig] = useState(configuration);
  const { matrix, name, id, isDraft } = config;
  const draftHasError = configurationError && configurationError.draft;
  const leadingVals = Object.values(config?.leading_values ?? {});

  const [leadingMode, setLeadingMode] = useState(); // undefined, 1, 2
  const matrixLevels = Array(matrix.length).fill(1);
  if (leadingVals.length > 1) matrixLevels.fill(2, leadingVals[1].y - 1); // [1, 1, 2] first two rows are level 1, the last one is 2

  return (
    <div className={classNames("mb-4")}>
      <div className={classNames("shadow px-4 py-2 mb-4", s.card)}>

        {!readOnly && (
          <div className={s.setLeadingsPanel}>
            <div className="flex items-center">
              <Target color="#445E9C" className="mr-2" />
              <Text weight="600">Výchozí hladiny</Text>
            </div>
            <div className="flex">
              <ActionButton
                secondary
                className={classNames(`mr-2 ${s.setLeadingBtn}`, {
                  [s.setLeadingBtnSelected]: leadingMode === 1
                })}
                onClick={() => setLeadingMode(leadingMode === 1 ? undefined : 1)}
              >
                <div className="flex items-center">
                  <Target color="#fff" className="mr-2" size="20" />
                  Hladina 1
                </div>
              </ActionButton>
              <ActionButton
                secondary
                className={classNames(`mr-2 ${s.setLeadingBtn}`, {
                  [s.setLeadingBtnSelected]: leadingMode === 2
                })}
                onClick={() => setLeadingMode(leadingMode === 2 ? undefined : 2)}
              >
                <div className="flex items-center">
                  <Target color="#fff" className="mr-2" size="20" />
                  Hladina 2
                </div>
              </ActionButton>
              <ActionButton
                width="auto"
                onClick={() => {
                  dispatch(
                    updateMatrix({
                      configuration: config,
                      countryCode: config.country_code,
                    })
                  );
                }}
                disabled={false/*configs.length === 0}
                className={configs.length === 0 ? "disabled" : ""*/}
              >
                Uložit
              </ActionButton>
              {!config.isDraft && <ActionButton className="ml-2" secondary bgColor='#f00' onClick={() => {
                dispatch(
                  openModal({
                    type: 'ConfirmMatrixDelete',
                    props: {
                      title: 'Opravdu chcete smazat tuto matici?',
                      content: null,
                      actions: (
                        <ActionButton primary="true" onClick={() => {
                          dispatch(
                            deleteMatrix({
                              id,
                              countryCode: config.country_code,
                            })
                          )
                        }}>
                          Smazat
                        </ActionButton>
                      ),
                    }
                  })
                )
              }}>
                <Trash2 color="#fff" size="20" />
              </ActionButton>}
            </div>
          </div>
        )}

        {configurationErrors && (Object.keys(configurationErrors?.response?.data?.errors ?? {}).map((key, index) => {
          const err = configurationErrors?.response?.data?.errors[key];
          if (!err) return null;
          switch (err.msg) {
            case 'err_greater_then_previous_value': {
              return (<div key={index} className="text-red-500">Chybná konfigurace matice</div>)
            }
            default: {
              return (<div key={index} className="text-red-500">{err.param}: {err.msg}</div>);
            }
          };
        }))}

        <div className="flex flex-row items-center">
          <div className="">
            <Text weight="600" size="1.2rem">
              #{id && !isDraft ? id : "N"}
            </Text>
          </div>
          <div className="flex-1 py-2 pl-4">
            <Input
              type="text"
              placeholder="Nová matice"
              value={name}
              disabled={readOnly}
              onChange={e => {
                e.persist();
                setConfig({ ...config, name: e.target.value });
              }}
            />
          </div>
          <div className="">
            <ActionButton
              secondary
              width="4rem"
              className="ml-4"
              disabled={readOnly}
              onClick={() => {
                setConfig({
                  ...config,
                  percentage: !config.percentage,
                  matrix: matrix.map((row, rowIdx) => {
                    return row.map((cell, cellIdx) => {
                      if (leadingVals.find(lv => (lv.x - 1) === cellIdx && (lv.y - 1) === rowIdx)) {
                        return !config.percentage ? 1 : 0
                      }
                      return cell
                    })
                  }),
                });
              }}
            >
              {config.percentage ? '%' : 'abs.'}
            </ActionButton>
          </div>
        </div>
        <table
          className={classNames(
            s.configurationTable,
            draftHasError && s.configurationErrorTable
          )}
        >
          <thead>
            <tr>
              <th />
              {matrix[0].map((_, i) => (
                <th key={index + "-" + i}>{i + 1}</th>
              ))}
              <th className={`${s.rSeparator} w-1`}>
                <button
                  className={s.changeSizeBtn}
                  disabled={readOnly || matrix[0].length <= 1}
                  onClick={() => {
                    // reduce_x
                    setConfig({ ...config, matrix: config.matrix.map(row => row.slice(0, -1)) });
                  }}
                >
                  -
                </button>
              </th>
              <th className="w-1">
                <button
                  className={s.changeSizeBtn}
                  disabled={readOnly || matrix[0].length > 4}
                  onClick={() => {
                    // grow_x
                    setConfig({ ...config, matrix: config.matrix.map(row => [...row, row[row.length - 1]]) });
                  }}
                >
                  +
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {matrix.map((row, rowIdx) => (
              <tr key={index + "-" + rowIdx}>
                <th>{indexToChar(rowIdx + 1)}</th>
                {row.map((value, colIdx) => {
                  const isLeading = leadingVals.find(
                    ({ x, y }) => x - 1 === colIdx && y - 1 === rowIdx
                  );
                  const valueNum = Number(value);
                  const leadingError = isLeading && valueNum !== 1;
                  const sameLevelAsPrevRow =
                    matrixLevels[rowIdx - 1] === matrixLevels[rowIdx];
                  const verticalError =
                    leadingError ||
                    (rowIdx > 0 && sameLevelAsPrevRow
                      ? valueNum > Number(matrix[rowIdx - 1][colIdx])
                      : false);
                  const horizontalError =
                    verticalError || valueNum > row[colIdx - 1];

                  return (
                    <td
                      key={index + "-" + rowIdx + "-" + colIdx}
                      className={classNames(
                        isLeading && s.leadingColumn,
                        leadingMode && s.hoverHighlight
                      )}
                    >
                      {leadingMode ? (
                        <ActionButton
                          className={s.textValue}
                          plain
                          disabled={
                            !!Object.entries(config.leading_values)
                              .filter(
                                ([leadingIdx, _]) =>
                                  leadingIdx !== leadingMode.toString()
                              )
                              .find(([_, { x, y }]) => rowIdx + 1 === y)
                          }
                          onClick={() => {
                            setLeadingMode();
                            // setLeadingValues(
                            setConfig({
                              ...config,
                              leading_values: updateMatrixLeadingValue({
                                leadingValues: config.leading_values,
                                leadingValNr: leadingMode,
                                x: colIdx + 1,
                                y: rowIdx + 1
                              }),
                            });
                          }}
                        >
                          <Text size="0.875rem" weight="400">
                            {value}
                          </Text>
                        </ActionButton>
                      ) : (
                        <Input
                          noContainer
                          className={classNames(s.matrixInput, {
                            [s.errInput]:
                              leadingError || verticalError || horizontalError
                          })}
                          type="number"
                          step="0.01"
                          placeholder="1"
                          value={value}
                          disabled={readOnly}
                          onChange={e => {
                            e.persist();
                            // setConfigs({
                            //   type: "update_value",
                            //   id,
                            //   x: colIdx,
                            //   y: rowIdx,
                            //   newValue: e.target.value
                            // });
                            config.matrix[rowIdx][colIdx] = e.target.value;
                            setConfig({ ...config });
                          }}
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
            <tr>
              <td>
                <button
                  className={s.changeSizeBtn}
                  disabled={readOnly || matrix.length <= 1}
                  onClick={() => {
                    // reduce_y
                    setConfig({ ...config, matrix: config.matrix.slice(0, -1) });
                  }}
                >
                  -
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <button
                  className={s.changeSizeBtn}
                  disabled={readOnly}
                  onClick={() => {
                    // grow_y
                    setConfig({ ...config, matrix: [...config.matrix, [...config.matrix[config.matrix.length - 1]]] });
                  }}
                >
                  +
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function NewMatrixForm({
  id,
  name,
  hasChanges,
  disabled,
  focus,
  actionTitle,
  onSubmit
}) {
  const [value, setValue] = useState(name || "");
  const handleChange = ({ target: { value } }) => {
    setValue(value);
  };
  const handleSubmit = event => {
    if (event) event.preventDefault();
    onSubmit({ name: value });
    setValue("");
  };

  if (!hasChanges) {
    disabled = name === value;
  }

  if (!value) {
    disabled = true;
  }

  return (
    <form className="flex flex-row items-center" onSubmit={handleSubmit}>
      <div className="">
        <Text weight="600" size="1.2rem">
          #{id ? id : "N"}
        </Text>
      </div>
      <div className="flex-1 py-2 pl-4">
        <Input
          autoFocus={focus}
          type="text"
          placeholder="Nová matice"
          value={value}
          onChange={handleChange}
        />
      </div>
      {actionTitle && handleSubmit && (
        <div className="pl-4">
          <ActionButton
            width="10rem"
            disabled={disabled}
            onClick={handleSubmit}
          >
            {actionTitle}
          </ActionButton>
        </div>
      )}
    </form>
  );
}

// function matrixReducer(configs, action) {
//   const id = action.id;
//   switch (action.type) {
    // case "reset_config":
    //   return action.newConfigs;
    // case "new_matrix":
    //   return configs.map(conf =>
    //     conf.id === id ? { ...conf, name: action.newName } : conf
    //   );
    // case "prepare_new_matrix":
    //   return [
    //     ...configs,
    //     {
    //       id: `${configs.length + 1}_${action.name}`,
    //       name: action.name,
    //       country_code: action.countryCode,
    //       matrix: createMatrix(
    //         configs?.[0]?.matrix?.[0]?.length,
    //         configs?.[0]?.matrix?.length
    //       ),
    //       isDraft: true
    //     }
    //   ];
    // case "update_value":
    //   return updateMatrixConfigurationValue({
    //     configs,
    //     id,
    //     x: action.x,
    //     y: action.y,
    //     value: action.newValue
    //   });
    // case "grow_x":
    //   return configs.map(c => {
    //     const newMatrix = c.matrix.map(row => [...row, row[row.length - 1]]);
    //     return { ...c, matrix: newMatrix };
    //   });
    // case "reduce_x":
    //   return configs.map(c => {
    //     const newMatrix = c.matrix.map(row => row.slice(0, -1));
    //     return { ...c, matrix: newMatrix };
    //   });
    // case "grow_y":
    //   return configs.map(c => {
    //     const newMatrix = [...c.matrix, c.matrix[c.matrix.length - 1]];
    //     return { ...c, matrix: newMatrix };
    //   });
    // case "reduce_y":
    //   return configs.map(c => {
    //     const newMatrix = c.matrix.slice(0, -1);
    //     return { ...c, matrix: newMatrix };
    //   });
//     default:
//       console.warn("missing action handler:", action); // TODO: remove!
//       return configs;
//   }
// }

export default function Configuration(props) {
  const {
    configurations = [],
    configurationError,
    configurationErrors,
    countryCode
  } = props;
  const dispatch = useContext(DispatchContext);
  const readOnly = useContext(ReadOnlyContext);

  const [configs, setConfigs] = useState(configurations);
  useEffect(() => {
    setConfigs(configurations);
  }, [configurations]);

  return (
    <div>
      {configs.map((config, index) => (
        <Matrix
          key={config.id || config.name}
          dispatch={dispatch}
          configurationError={configurationError}
          configurationErrors={configurationErrors?.[config.id]}
          index={index}
          configuration={config}
          readOnly={readOnly}
        />
      ))}
      {!readOnly && (
        <div className="shadow px-4 py-2 mb-4 bg-white">
          <NewMatrixForm
            actionTitle="Nová matice"
            onSubmit={({ name }) => {
              // setConfigs({
              //   type: "prepare_new_matrix",
              //   name,
              //   countryCode
              // });
              setConfigs([
                ...configs,
                {
                  id: `${configs.length + 1}_${name}`,
                  name: name,
                  country_code: countryCode,
                  leading_values: configs?.[0]?.leading_values || {},
                  matrix: createMatrix(
                    configs?.[0]?.matrix?.[0]?.length,
                    configs?.[0]?.matrix?.length
                  ),
                  isDraft: true
                }
              ])
            }}
          />
        </div>
      )}
    </div>
  );
}
