import { takeEvery, put } from "redux-saga/effects";
import {
  submitNewLimit,
  requestLimitTypes,
  submitLimitBatchUpdate,
  requestPricingGroupLimits,
  closeModal
} from "../store/actions";
import { success } from "redux-saga-requests";

export function* handleNewLimit() {
  yield put(requestLimitTypes());
}

export function* handlesubmitLimitBatchUpdate({ meta }) {
  yield put(requestPricingGroupLimits({ countryCode: meta.countryCode, limitIdFilter: meta.limitIdFilter }));
  yield put(closeModal());
}

export default function* limitsSaga() {
  yield takeEvery(success(submitNewLimit), handleNewLimit);
  yield takeEvery(
    success(submitLimitBatchUpdate),
    handlesubmitLimitBatchUpdate
  );
}
