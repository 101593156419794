import React from "react";
import * as s from "./Checkbox.module.css";
import PropTypes from "prop-types";

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string
};

export default function Checkbox({
  checked = false,
  onChange,
  id,
  label,
  labelClass,
  className,
  labelPositionLeft = false,
  disabled
}) {
  return (
    <div className={`${s.checkboxWrap} ${className}`}>
      {labelPositionLeft && (
        <label htmlFor={id} className={labelClass || s.defaultLeftLabel}>
          {label}
        </label>
      )}
      <div className={`${s.checkbox} ${disabled ? s.checkboxDisabled : ''}`}>
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <label htmlFor={id} />
      </div>
      {!labelPositionLeft && (
        <label htmlFor={id} className={labelClass || s.defaultRightLabel}>
          {label}
        </label>
      )}
    </div>
  );
}
