import React, { useState } from "react";
import { closeModal } from "../../store/actions";
import * as s from "./Modal.module.css";
import { levelLimit } from "../../library/matrix";
import Text from "../ui/Text/Text";
import Input from "../ui/Input/Input";
import Button, { ActionButton } from "../ui/Button/Button";
import { useSubmitColumnBatchUpdate } from "../../requests/submitColumnBatchUpdate";
import { useToggle } from "../../library/hooks";
import classNames from "classnames";

export function ColumnBatchUpdate({ state: { modal }, dispatch }) {
  const {
    group,
    priceListId,
    countryCode,
    refetch,
    currency,
    urlParams,
    pricingGroupIds
  } = modal.props;
  const [value, setValue] = useState("");
  const coercedValue = Number.parseFloat(value.replace(",", "."));
  const {
    isEnabled: inPercentages,
    enable: setPercentages,
    disable: unsetPercentages
  } = useToggle(false);
  const [inProgress, setInProgress] = useState(false);
  const { mutateAsync: submitColumnBatchUpdate } = useSubmitColumnBatchUpdate({
    priceListId,
    countryCode
  });

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSubmit = async event => {
    if (event) event.preventDefault();
    setInProgress(true);
    try {
      await submitColumnBatchUpdate({
        value: coercedValue,
        inPercentages,
        pricingGroupIds,
        urlParams,
        x: group.x,
        y: group.y
      });
      handleCloseModal();
    } finally {
      refetch();
    }
  };

  return (
    <div>
      <div className={`flex flex-row items-center ${s.header}`}>
        <div className="flex-1">
          <Text size="1.25rem">
            <strong>{levelLimit(group.level, group.limit)}</strong> - Hromadná
            změna hodnot sloupce
          </Text>
        </div>
        <Button width="10rem" short secondary onClick={handleCloseModal}>
          Zavřít
        </Button>
      </div>
      <form onSubmit={handleSubmit} className={s.content}>
        <label className="py-2 block flex">
          <div className="w-32 flex h-10 items-center">
            <Text weight="600">HODNOTA</Text>
          </div>
          <div className="w-full flex flex-row justify-end">
            <div className="flex items-center">
              <Input
                autoFocus
                value={value}
                onChange={e => {
                  const value = e.target.value;
                  if (value.includes("%")) {
                    setPercentages();
                  }
                  setValue(value);
                }}
                placeholder="např. -10"
                required
              />
            </div>
            <button
              type="button"
              className={classNames("ml-1", s.toggleButton, {
                [s.activeBtn]: !inPercentages
              })}
              onClick={unsetPercentages}
            >
              {currency}
            </button>
            <button
              type="button"
              className={classNames("ml-1", s.toggleButton, {
                [s.activeBtn]: inPercentages
              })}
              onClick={setPercentages}
            >
              %
            </button>
          </div>
        </label>
        <hr />
        <div className="py-4">
          <ActionButton
            type="submit"
            disabled={inProgress || !coercedValue}
            className={inProgress ? "inProgress" : ""}
          >
            {inProgress
              ? "Zpracovávám"
              : `Změnit o ${coercedValue || 0} ${
                  inPercentages ? "%" : currency
                }`}
          </ActionButton>
        </div>
      </form>
    </div>
  );
}
