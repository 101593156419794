import { takeEvery, put } from "redux-saga/effects";
import {
  patchPriceList,
  deletePriceList,
  requestPriceLists,
  submitNewPriceList,
  // requestPricingColl,
  prepareDownloads,
  keboolaImport,
  requestSession,
  closeModal,
  recountKPI,
  requestPriceListDetail,
  submitCommoditiesMatrixBatchUpdate
} from "../store/actions";
import { success } from "redux-saga-requests";

export function* refreshSession() {
  yield put(closeModal());
  yield put(requestSession());
}

export function* handlePatchPriceList() {
  yield put(requestPriceLists());
  yield put(closeModal());
}

export function* handleDeletePriceList() {
  yield put(requestPriceLists());
  yield put(closeModal());
}

export function* handleBatchPatchPriceList({ meta }) {
  const { success } = meta;
  success()
  yield put(closeModal());
}

export function* refetchPriceListDetail({ meta }) {
  yield put(requestPriceListDetail(meta.priceListId, { kpi: true }));
}

export default function* priceListsSaga() {
  yield takeEvery(success(patchPriceList), handlePatchPriceList);
  yield takeEvery(success(deletePriceList), handleDeletePriceList);
  yield takeEvery(success(submitNewPriceList), handlePatchPriceList);
  yield takeEvery(success(prepareDownloads), handlePatchPriceList);
  yield takeEvery(success(keboolaImport), refreshSession);
  yield takeEvery(success(recountKPI), refetchPriceListDetail);
  yield takeEvery(
    success(submitCommoditiesMatrixBatchUpdate),
    handleBatchPatchPriceList
  );
}
