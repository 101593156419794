import React, { useState } from "react";
import { Modal } from "./Modal";
import { ActionButton } from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import Text from "../ui/Text/Text";
import {
  patchPriceList,
} from "../../store/actions";

export function EditPriceListModal({ dispatch, state: { modal } }) {
  const { priceList } = modal.props;
  const [newTitle, setNewTitle] = useState(priceList.title);
  return (
    <Modal
      dispatch={dispatch}
      title="Úprava ceníku"
      content={<div>
        <Text weight="600">Název</Text>
        <Input
          autoFocus
          value={newTitle}
          onChange={e => {
            const value = e.target.value;
            setNewTitle(value);
          }}
          required
        />
      </div>}
      actions={
        <ActionButton
          onClick={() => {
            dispatch(patchPriceList(priceList.id, { title: newTitle }));
          }}
        >
          Upravit ceník
        </ActionButton>
      }
    />
  );
}
