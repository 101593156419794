import { setContext, takeEvery, put, fork } from "redux-saga/effects";
import { getCookie, setCookie } from "../library/auth";
import {
  setAccessToken,
  initializeApplication,
  requestSignIn
} from "../store/actions";
import { success } from "redux-saga-requests";
import requestsSaga from "./requests";
import pricingGroupSaga from "./pricing-group";
import limitsSaga from "./limits";
import priceListsSaga from "./price-lists";
import exchangeRatesSaga from "./exchange-rates";
import matrixSaga from "./matrix";

export function* handleInitialize() {
  const token = getCookie("access-token");
  yield put(setAccessToken(token));
}

export function* handleSignInSuccess({ payload }) {
  const { access_token } = payload.data;
  setCookie("access-token", access_token);
  yield put(setAccessToken(access_token));
}

export default function* rootSaga(axios) {
  // Allow access axios instance with current access token from all all sagas
  yield setContext({ axios });
  yield takeEvery(setAccessToken, ({ payload: token }) => {
    axios.defaults.headers.common["X-Access-Token"] = token;
  });

  // Handles data requests - needs to be before any network request
  yield fork(requestsSaga);

  // Graps token from cookie and puts it to state
  yield takeEvery(initializeApplication, handleInitialize);

  // Set cookie with access token on sucessful sign in
  yield takeEvery(success(requestSignIn), handleSignInSuccess);

  // Fork other features sagas
  yield fork(pricingGroupSaga);
  yield fork(limitsSaga);
  yield fork(priceListsSaga);
  yield fork(exchangeRatesSaga);
  yield fork(matrixSaga);
}
