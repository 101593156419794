export const getFirstAndLastInitials = name => {
  const allInitials = (name || "")
    .split(" ")
    .filter(n => n.trim() !== "")
    .map(n => n[0].toUpperCase());
  let initials = [];
  if (allInitials.length > 2) {
    initials = [allInitials[0], allInitials[allInitials.length - 1]];
  } else {
    initials = allInitials;
  }
  return initials.join("");
};
