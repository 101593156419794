import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import axios from "axios";

import reducers from "./reducers";
import rootSaga from "../sagas";

const { REACT_APP_API_HOST } = process.env;

export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    (typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  const axiosInstance = axios.create({
    baseURL: REACT_APP_API_HOST
    // timeout: 1000,
  });

  sagaMiddleware.run(rootSaga, axiosInstance);

  return {
    ...store,
    dispatch: action => {
      requestAnimationFrame(() => {
        // FIXME: action initializeApplication ([3] initialize app) causes Violation and tooks ~80ms
        console.log(action.type);
        store.dispatch(action);
      });
    },
    subscribe: listener => {
      return store.subscribe(() => {
        const state = store.getState();
        if (process.env.NODE_ENV === "development") {
          window.__state = state;
        }
        listener(state);
      });
    }
  };
};
