import React, { PureComponent } from "react";
import { version } from "../../../package.json";
import { requestSignIn } from "../../store/actions";
import * as s from "./index.module.css";
import { User } from "react-feather";
import ButtonWithProgress from "../../components/ButtonWithProgress";
import { SignInHeader } from "../../components/Header";
import idx from "idx";

const mapStateToProps = state => ({
  inProgress: state.requests.signIn.pending > 0,
  error: idx(state, _ => _.requests.signIn.error.response.data.error),
  appVersion: version,
  appName: "Malfini Pricing"
});

export default class SignIn extends PureComponent {
  state = {
    email: "",
    password: ""
  };

  logIn = e => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { email, password } = this.state;
    dispatch(requestSignIn({ email, password }));
  };

  setEmail = e => this.setState({ email: e.target.value });
  setPassword = e => this.setState({ password: e.target.value });

  render() {
    const { inProgress, error, appName, appVersion } = mapStateToProps(
      this.props.state
    );

    const { email, password } = this.state;

    return (
      <div>
        <SignInHeader />

        <div className={s.content}>
          <div className={s.signInDialog}>
            <div className={s.app}>
              <div className={s.appName}>{appName}</div>
              <div className={s.appVersion}>{appVersion}</div>
            </div>
            <div className={s.signInForm}>
              <div className={s.title}>
                <div className={s.titleIcon}>
                  <User color="white" />
                </div>
                <div className={s.titleText}>Sign In</div>
              </div>
              <form onSubmit={e => this.logIn(e)}>
                <label htmlFor="email" className={s.label}>
                  <span>Username</span>
                  <input
                    autoFocus={true}
                    type="text"
                    name="email"
                    id="email"
                    className={s.input}
                    value={this.state.email}
                    onChange={e => this.setEmail(e)}
                  />
                </label>
                <label htmlFor="password" className={s.label}>
                  <span>Password</span>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className={s.input}
                    value={password}
                    onChange={e => this.setPassword(e)}
                  />
                </label>
                <p className={s.errorMsg}>{error}</p>
                <div className="right">
                  <ButtonWithProgress
                    type="submit"
                    name="logIn"
                    className={s.logInBtn}
                    disabled={email.trim() === "" || password.trim() === ""}
                    inProgress={inProgress}
                  >
                    Log In
                  </ButtonWithProgress>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
