import * as React from "react";
import classNames from "classnames";
import "./ButtonWithProgress.css";

class ButtonWithProgress extends React.Component {
  render() {
    const { children, inProgress, className, disabled, ...props } = this.props;

    return (
      <button
        {...props}
        disabled={inProgress || disabled}
        className={classNames("ButtonWithProgress", className, {
          clickable: !inProgress
        })}
      >
        <div className="ldsEllipsisWrap">
          <div className={classNames("lds-ellipsis", { hidden: !inProgress })}>
            <div />
            <div />
            <div />
            <div />
          </div>
          <span style={{ visibility: inProgress ? "hidden" : "visible" }}>
            {children}
          </span>
        </div>
      </button>
    );
  }
}

export default ButtonWithProgress;
