import React from "react";
import PropTypes from "prop-types";
import iconColor from "../../images/icon-color-comodity.png";
import iconWhite from "../../images/icon-white-comodity.png";

IconColorful.propTypes = {
  colorful: PropTypes.bool.isRequired,
  size: PropTypes.string
};

export default function IconColorful({ colorful, size = "2rem" }) {
  return (
    <span
      style={{
        width: size,
        height: size,
        display: "inline-block"
      }}
    >
      <img
        alt="colorful icon"
        width="100%"
        height="100%"
        src={colorful ? iconColor : iconWhite}
      />
    </span>
  );
}
