import React from "react";
import Header from "../components/Header";
import ScreenHeadline from "../components/ui/ScreenHeadline/ScreenHeadline";
import idx from "idx";

const mapStateToProps = state => ({
  user: idx(state, _ => _.requests.session.data.user)
});

export default function NotFound({ state }) {
  const { user } = mapStateToProps(state);

  return (
    <div>
      <Header user={user} />
      <ScreenHeadline title="Page not found" />
    </div>
  );
}
