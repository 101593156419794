import React from "react";
import { Modal } from "./Modal";
import { ActionButton } from "../ui/Button/Button";
import { closeModal } from "../../store/actions";

export default function ResetToPredictionConfirm({
  state: { modal },
  dispatch
}) {
  const { update } = modal.props;

  return (
    <Modal
      dispatch={dispatch}
      title="Reset na výchozí hodnotu"
      content={
        <div className="mt-6 mb-10">
          Opravdu chcete resetovat tuto hodnotu na výchozí?
        </div>
      }
      actions={
        <ActionButton
          bgColor="#ef1d1d"
          onClick={async e => {
            await update();
            dispatch(closeModal());
          }}
          hasLoading
        >
          Resetovat
        </ActionButton>
      }
    />
  );
}
