import React, { useContext } from "react";
import { RefreshCcw } from "react-feather";
import moment from "moment";
import { keboolaImport, openModal, requestSession } from "../../store/actions";
import { DispatchContext } from "../../App";
import s from "./Import.module.css";
import { ActionButton } from "../../components/ui/Button/Button";
import { Modal } from "../../components/Modals/Modal";

export function ImportDataConfirmPopup({ dispatch }) {
  return (
    <Modal
      dispatch={dispatch}
      title="Aktualizace dat"
      content={
        <div className="mt-6 mb-10">
          Opravdu chcete aktualizovat data? Vámi vyplněné hodnoty zůstanou
          zachovány.
        </div>
      }
      actions={
        <ActionButton
          onClick={() => {
            dispatch(keboolaImport());
          }}
          hasLoading
        >
          Aktualizovat
        </ActionButton>
      }
    />
  );
}

export function ImportDataSection({ keboola }) {
  const dispatch = useContext(DispatchContext);
  const inProgress =
    keboola &&
    (keboola.status === "waiting" || keboola.status === "processing");
  const failed = keboola && keboola.status === "error";
  let content;

  if (inProgress)
    content = (
      <>
        <ActionButton
          className={s.importDataBtn}
          title="Zjistit aktuální stav"
          onClick={() => dispatch(requestSession())}
        >
          <RefreshCcw />
        </ActionButton>
        <p>Probíhá aktualizace dat. Akce může trvat 25 - 30 minut.</p>
      </>
    );
  else if (failed)
    content = (
      <>
        <ActionButton
          className={s.importDataBtn}
          onClick={() =>
            dispatch(
              openModal({
                type: "ImportDataConfirmPopup"
              })
            )
          }
        >
          Aktualizovat data
        </ActionButton>
        <p>
          Poslední aktualizace z{" "}
          {moment(keboola.created_at).format("D. M. YYYY")}{" "}
          <strong>selhala</strong>. Zkuste to znovu.
        </p>
      </>
    );
  else
    content = (
      <>
        <ActionButton
          className={s.importDataBtn}
          onClick={() =>
            dispatch(
              openModal({
                type: "ImportDataConfirmPopup"
              })
            )
          }
        >
          Aktualizovat data
        </ActionButton>
        {keboola && keboola.finished_at && (
          <p>
            Poslední aktualizace dat{" "}
            {moment(keboola.finished_at).format("D. M. YYYY HH:mm")}
          </p>
        )}
      </>
    );

  return (
    <div className={s.importData}>
      <div className={s.importDataInner}>{content}</div>
    </div>
  );
}
