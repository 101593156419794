import { takeEvery, put } from "redux-saga/effects";
import {
  assignNomenclatureToPricingGroup,
  requestNomenclaturesForPricingGroup,
  requestUnassignedNomenclatures,
  removeNomenclatureFromPricingGroup,
  requestPricingGroups,
  createNewPricingGroup,
  updatePricingGroup,
} from "../store/actions";
import { success } from "redux-saga-requests";

export function* handlePricingGroupChange({ meta }) {
  const {
    pricingGroupId,
    nomenclaturesForPricingGroupSearch,
    unassignedNomenclaturesSearch
  } = meta;
  yield put(
    requestNomenclaturesForPricingGroup({
      pricingGroupId,
      search: nomenclaturesForPricingGroupSearch
    })
  );
  yield put(
    requestUnassignedNomenclatures({ search: unassignedNomenclaturesSearch })
  );
}

export function* handleNewPricingGroupCreation() {
  yield put(requestPricingGroups());
}

export function* handlePricingGroupUpdate() {
  yield put(requestPricingGroups());
}

/**
 * Loads next page of auto assign suggestions
 */

export default function* pricingGroupSaga() {
  yield takeEvery(
    success(assignNomenclatureToPricingGroup),
    handlePricingGroupChange
  );
  yield takeEvery(
    success(removeNomenclatureFromPricingGroup),
    handlePricingGroupChange
  );
  yield takeEvery(
    success(createNewPricingGroup),
    handleNewPricingGroupCreation
  );
  yield takeEvery(
    success(updatePricingGroup),
    handlePricingGroupUpdate
  );

}
