import React, { useState, useEffect } from "react";
import {
  closeModal,
  createNewPricingGroup,
  updatePricingGroup,
  requestCommodities
} from "../../store/actions";
import * as s from "./NewLimit.module.css"; // TODO extract common styles
import Button, { ActionButton } from "../ui/Button/Button";
import Text from "../ui/Text/Text";
import Input from "../ui/Input/Input";
import Select from "../ui/Select/Select";
import Checkbox from "../ui/Checkbox/Checkbox";

export const NewPricingGroupType = "NewPricingGroup";

export function NewPricingGroup({ state: { requests, modal }, dispatch }) {
  const { model } = modal?.props || {};
  useEffect(() => dispatch(requestCommodities()), [dispatch]);

  const commodities = (requests.commodities?.data || []).map(
    ({ code, name }) => ({
      value: code,
      title: `${code} - ${name}`
    })
  );
  const [title, setTitle] = useState(model?.title || "");
  const [isColorful, setIsColorful] = useState(model?.is_colorful || false);
  const [commodity, setCommodity] = useState(model?.commodity_code || "");
  const handleSubmit = event => {
    if (event) event.preventDefault();

    const fn = model?.id ? updatePricingGroup : createNewPricingGroup
    dispatch(fn({ id: model?.id, title, isColorful, commodity }));
  };
  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const resErrorMsg = {
    404: 'Ceníková skupina s tímto ID neexistuje',
    409: 'Ceníková skupina s tímto názvem, komoditou a barvou již exituje',
    500: 'Někde se stala chyba',
  }

  const accessor = model?.id ? 'updatePricingGroup' : 'newPricingGroup'
  const resError = requests[accessor].error?.response?.status;
  const errors = requests[accessor].error?.response?.data.errors || {};
  const processing = requests[accessor].pending > 0;

  return (
    <div>
      <div className={`flex flex-row items-center ${s.header}`}>
        <div className="flex-1">
          <Text size="1.25rem">{model?.id ? 'Úprava cen. skupiny' : 'Nová cen. skupina'}</Text>
        </div>
        <Button width="10rem" short secondary onClick={handleCloseModal}>
          Zavřít
        </Button>
      </div>
      <form onSubmit={handleSubmit} className={s.content}>
        {[404, 409, 500].includes(resError) && (
          <p className="mt-0 mb-2">
            <span className="text-xs text-red-500">{resErrorMsg[resError]}</span>
          </p>
        )}
        <label className="py-2 block flex leading-loose">
          <div className="w-32 flex h-10 items-center">
            <Text weight="600">NÁZEV</Text>
          </div>
          <div className="w-full">
            <Input
              type="text"
              maxLength="100"
              hasError={Boolean(errors.title)}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            <p className="m-0">
              {errors.title && (
                <span className="text-xs text-red-500">{errors.title.msg}</span>
              )}
            </p>
          </div>
        </label>
        <label className="py-2 block flex leading-loose" htmlFor="commodity">
          <div className="w-32 flex h-10 items-center">
            <Text weight="600">KOMODITA</Text>
          </div>
          <Select
            id="commodity"
            options={commodities}
            value={commodity}
            onChange={e => {
              setCommodity(e.target.value);
            }}
          />
          <p className="m-0">
            {errors.commodity && (
              <span className="text-xs text-red-500">{errors.commodity.msg}</span>
            )}
          </p>
        </label>
        <label className="py-2 block flex items-center leading-loose">
          <Checkbox
            id="isColorful"
            checked={isColorful}
            onChange={() => setIsColorful(!isColorful)}
            label="BAREVNÁ"
          />
          <p className="m-0">
            {errors.isColorful && (
              <span className="text-xs text-red-500">{errors.isColorful.msg}</span>
            )}
          </p>
        </label>

        <hr />
        <div className="py-4">
          <ActionButton type="submit" onClick={handleSubmit}>
            {processing ? "..." : (model?.id ? "Upravit ceníkovou skupinu" : "Vytvořit ceníkovou skupinu")}
          </ActionButton>
        </div>
      </form>
    </div>
  );
}
