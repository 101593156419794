import React from "react";
import { Modal } from "./Modal";
import { ActionButton } from "../ui/Button/Button";
import { useSubmitColumnBatchUpdate } from "../../requests/submitColumnBatchUpdate";
import { closeModal } from "../../store/actions";

export default function BulkResetToPredictionConfirm({
  state: { modal },
  dispatch
}) {
  const {
    group,
    priceListId,
    countryCode,
    refetch,
    urlParams,
    pricingGroupIds
  } = modal.props;
  const { mutateAsync: submitColumnBatchUpdate } = useSubmitColumnBatchUpdate({
    priceListId,
    countryCode
  });

  return (
    <Modal
      dispatch={dispatch}
      title="Reset na výchozí hodnoty"
      content={
        <div className="mt-6 mb-10">
          Opravdu chcete resetovat vyplněné hodnoty na výchozí?
        </div>
      }
      actions={
        <ActionButton
          bgColor="#ef1d1d"
          onClick={async e => {
            await submitColumnBatchUpdate({
              pricingGroupIds,
              original: true,
              urlParams,
              x: group.x,
              y: group.y
            });
            refetch();
            dispatch(closeModal());
          }}
          hasLoading
        >
          Resetovat
        </ActionButton>
      }
    />
  );
}
