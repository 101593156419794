import React, { useState, useRef, useContext } from "react";
import * as s from "./Header.module.css";
import Logo from "../images/logo.svg";
import { Link } from "@reach/router";
import UserMenu from "./UserMenu";
import { requestPriceLists } from "../store/actions";
import _ from "lodash";
import { DispatchContext } from "../App";
import GlobalErrors from "./ui/GlobalErrors";

export const SignInHeader = () => {
  return (
    <header className={s.header}>
      <div className={s.headerInner}>
        <div className={s.imgHeadingWrap}>
          <Link to="/">
            <img src={Logo} width="60" height="60" alt="Malfini" />
          </Link>
        </div>
      </div>
    </header>
  );
};

const menulist = [
  { path: "/matrix", title: "Matice" },
  { path: "/pricing-groups", title: "Cen. Skupiny" },
  { path: "/limits", title: "Limity" },
  { path: "/exchange-rates", title: "Kurzy" }
];

function Header({ user, children, priceLists }) {
  const [isOpen, setIsOpen] = useState(false);
  const timer = useRef();
  const dispatch = useContext(DispatchContext);

  const activePriceLists = priceLists
    ? _.orderBy(
        priceLists.filter(pl => !pl.finished),
        ["created_at"],
        ["asc"]
      )
    : [];
  return (
    <div className={s.headerWrapperFixed}>
      <header className={s.header}>
        <div className={s.headerInner}>
          <Link to="/" className={s.link}>
            <div className={s.imgHeadingWrap}>
              <img src={Logo} width="60" height="60" alt="Malfini" />

              <div className={s.appName}>Pricing</div>
            </div>
          </Link>
          <div className={s.navigation}>
            <ul className={s.navItems}>
              <li
                key="Ceníky"
                className="float-left relative"
                aria-haspopup="true"
                aria-expanded={isOpen}
                onMouseEnter={() => {
                  clearTimeout(timer.current);
                  !priceLists && dispatch && dispatch(requestPriceLists());
                  setIsOpen(true);
                }}
                onMouseLeave={() =>
                  (timer.current = setTimeout(() => {
                    setIsOpen(false);
                  }))
                }
              >
                <Link to="/" className={s.navItem}>
                  Ceníky
                </Link>
                {isOpen && (
                  <div className={s.activeListMenu}>
                    {activePriceLists.map(list => (
                      <Link
                        key={list.id}
                        to={`/price-list/${list.id}`}
                        className={s.activeListMenuItem}
                        onClick={() => setIsOpen(false)}
                      >
                        {list.title}
                      </Link>
                    ))}
                  </div>
                )}
              </li>
              {menulist.map(({ path, title }) => (
                <li key={title} className="float-left">
                  <Link to={path} className={s.navItem}>
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="icons">
            {user && <UserMenu userName={user.display_name || user.email} />}
          </div>
        </div>
      </header>
      <GlobalErrors />
      {children}
    </div>
  );
}

export default Header;
