import ReactDOM from "react-dom";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as s from "./index.module.css";
import classNames from "classnames";
import { closeModal } from "../../store/actions";
import { ConfirmMatrixDelete } from "./ConfirmMatrixDelete";
import { NewPriceList } from "./NewPriceList";
import { EditPriceListModal } from "./EditPriceListModal";
import { DeletePriceListModal } from "./DeletePriceListModal";
import { NewLimit } from "./NewLimit";
import { NewPricingGroup } from "./NewPricingGroup";
import { ColumnBatchUpdate } from "./ColumnBatchUpdate";
import { ImportDataConfirmPopup } from "../../screens/PriceLists/Import";
import { LimitBatchUpdate } from "./LimitBatchUpdate";
import { CommoditiesMatrixBatchUpdate } from "./CommoditiesMatrixBatchUpdate";
import BulkResetToPredictionConfirm from "./BulkResetToPredictionConfirm";
import ResetToPredictionConfirm from "./ResetToPredictionConfirm";

export const Modals = {
  ConfirmMatrixDelete,
  NewPriceList,
  EditPriceListModal,
  DeletePriceListModal,
  NewLimit,
  NewPricingGroup,
  ColumnBatchUpdate,
  ImportDataConfirmPopup,
  LimitBatchUpdate,
  CommoditiesMatrixBatchUpdate,
  BulkResetToPredictionConfirm,
  ResetToPredictionConfirm
};

ModalPortal.propTypes = {
  // element: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired
};

export function ModalPortal({ element, dispatch, state }) {
  const { type, open, size } = state.modal;
  const Content = Modals[type] || (() => <>Undefined modal</>);

  const handleOverlayClick = () => {
    dispatch(closeModal());
  };

  // Handle esc keypress
  useEffect(() => {
    const handler = ({ key }) => {
      if (key === "Escape") {
        dispatch(closeModal());
      }
    };

    if (open) {
      window.addEventListener("keyup", handler);
    } else {
      window.removeEventListener("keyup", handler);
    }
  }, [dispatch, open]);

  return ReactDOM.createPortal(
    open ? (
      <div>
        <div
          onClick={handleOverlayClick}
          className={classNames(s.overlay, open && s.overlayOpen)}
        />
        <div className={classNames(s.modal, s[size])}>
          <Content dispatch={dispatch} state={state} />
        </div>
      </div>
    ) : null,
    element
  );
}
