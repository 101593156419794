import React from "react";
import * as s from "./FinishedPriceLists.module.css";
import * as format from "../../library/format";
import ScreenHeadline from "../../components/ui/ScreenHeadline/ScreenHeadline";
import { ExportButtons } from "./PriceListCard";
import Button from "../../components/ui/Button/Button";
import {
  openModal,
} from "../../store/actions";

export default function FinishedPriceLists({ priceLists, disableHeavyActions, dispatch }) {
  if (priceLists.length === 0) return null;

  return (
    <div className={`mt-5 ${s.container}`}>
      <ScreenHeadline title="Dokončené ceníky" />

      <table className={s.finishedPriceLists}>
        <tbody>
          {priceLists.map(pl => (
            <tr key={pl.id} className={s.row}>
              <td className={s.cell}>
                <b>{pl.title}</b>
              </td>
              <td className={s.cell}>
                {format.date(pl.from)} -{" "}
                {format.date(pl.to)}
              </td>
              <td>
                <ExportButtons priceList={pl} disabled={disableHeavyActions} />
              </td>
              <td className={`${s.cell} text-right`}>
                <Button plain secondary path={`/price-list/${pl.id}`}>Otevřít</Button>
                <Button
                  plain
                  secondary
                  onClick={() =>
                    dispatch(openModal({
                      type: "DeletePriceListModal",
                      props: {
                        priceList: pl
                      }
                    }))
                  }
                >
                  Smazat
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
