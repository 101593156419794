import React, { useEffect, useState } from "react";
import {
  submitNewLimit,
  updateLimitFormValue,
  closeModal
} from "../../store/actions";
import * as s from "./NewLimit.module.css";
import Button, { ActionButton } from "../ui/Button/Button";
import Text from "../ui/Text/Text";
import Input from "../ui/Input/Input";
import Select from "../ui/Select/Select";
import idx from "idx";

// const { REACT_APP_LIMIT_VALUES_COUNT: LIMIT_VALUES_COUNT } = process.env;
const limitPlaceholderValues = count =>
  [...Array(count ? Number(count) + 1 : 0)]
    .map((_, index) => Math.pow(10, index) - 1)
    .slice(1);

export const NewLimitType = "NewLimit";

export function NewLimit({ state: { modal, limitForm, requests }, dispatch }) {
  const { countryCode } = modal.props;

  const [limitOpts, setLimitOpts] = useState();

  const handleChange = kind => ({ target: { value } }) => {
    dispatch(updateLimitFormValue({ kind, value }));
  };
  const handleValueChange = index => ({ target: { value } }) => {
    const values = [...limitForm.values];
    values[index] = value;
    handleChange("values")({ target: { value: values } });
  };
  const handleSubmit = event => {
    if (event) event.preventDefault();

    dispatch(submitNewLimit(limitForm));
  };
  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  const errors =
    idx(requests, _ => _.newLimit.error.response.data.errors) || {};
  const processing = idx(requests, _ => _.newLimit.pending) > 0;

  const placeholders = limitPlaceholderValues(limitForm.size || 0);
  const calculatedMinimalEdgeValues = index =>
    (Number(limitForm.values[index - 1]) || placeholders[index - 1] || 0) + 1;

  useEffect(() => {
    if (requests.matrixConfigurations[countryCode]?.data?.length) {
      const limitOptions = Object.values(requests.matrixConfigurations[countryCode].data.reduce((acc, mc) => {
        const value = mc.matrix?.[0]?.length - 1;
        acc[value] = { title: `${value + 1}`, value };
        return acc;
      }, { 0: { title: '0', value: 0 } }));
      setLimitOpts(limitOptions);
      handleChange("size")({ target: { value: limitOptions[0].value } });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requests.matrixConfigurations[countryCode]?.length]);

  return (
    <div>
      <div className={`flex flex-row items-center ${s.header}`}>
        <div className="flex-1">
          <Text size="1.25rem">Vytvořit nový limit</Text>
        </div>
        <Button width="10rem" short secondary onClick={handleCloseModal}>
          Zavřít
        </Button>
      </div>
      <form onSubmit={handleSubmit} className={s.content}>
        <label className="py-2 block flex leading-loose">
          <div className="w-32 flex h-10 items-center">
            <Text weight="600">NÁZEV</Text>
          </div>
          <div className="w-full">
            <Input
              type="text"
              maxLength="12"
              hasError={Boolean(errors.title)}
              value={limitForm.title}
              onChange={handleChange("title")}
            />
            <p className="m-0">
              {errors.title && (
                <span className="text-xs text-red-500">{errors.title.msg}</span>
              )}
            </p>
          </div>
        </label>

        {!!limitOpts?.length && <label className="py-2 block flex leading-loose">
          <div className="w-32 flex h-10 items-center">
            <Text weight="600">POČET INTERVALŮ</Text>
          </div>
          <div className="w-full">
            <Select
              value={limitForm.size}
              options={limitOpts}
              onChange={handleChange("size")}
            />
            <p className="m-0">
              {errors.title && (
                <span className="text-xs text-red-500">{errors.title.msg}</span>
              )}
            </p>
          </div>
        </label>}
        {!!placeholders?.length && <label className="py-2 block flex leading-loose">
          <div className="w-32 flex h-10 items-center">
            <Text weight="600">HODNOTY</Text>
          </div>
          <div className="w-full flex flex-row">
            {placeholders.map((placeholder, index) => (
              <div key={placeholder} className="flex items-center ">
                <div className={"" + (index ? "ml-4" : "")}>
                  <Text className="whitespace-no-wrap">
                    {calculatedMinimalEdgeValues(index)} –
                  </Text>
                </div>
                <div className="ml-1">
                  <Input
                    type="number"
                    placeholder={placeholder}
                    hasError={Boolean(errors.values)}
                    onChange={handleValueChange(index)}
                    value={limitForm.values[index] || ""}
                  />
                </div>
                <p className="m-0">
                  {errors.from && (
                    <span className="text-xs text-red-500">
                      {errors.values.msg}
                    </span>
                  )}
                </p>
              </div>
            ))}
          </div>
        </label>}
        <hr />
        <div className="py-4">
          <ActionButton type="submit" onClick={handleSubmit} disabled={!placeholders?.length}>
            {processing ? "..." : "Vytvořit nový limit"}
          </ActionButton>
        </div>
        <div className="pb-2 text-center">
          {/* <Text>Nově vytvořený ceník nebude okamžitě platný</Text> */}
        </div>
      </form>
    </div>
  );
}
