import React, { useContext, useEffect, useState } from "react";
import { Link } from "@reach/router";
import classNames from "classnames";
import * as s from "./PriceListCard.module.css";
import * as format from "../../library/format";
import Button, { ActionButton } from "../../components/ui/Button/Button";
import Text from "../../components/ui/Text/Text";
import * as Icons from "react-feather";
import { lastYear } from "../../library/date";
import { prepareDownloads } from "../../store/actions";
import { DispatchContext } from "../../App";
import { apiDownloadPricing } from "../../library/routes";

function downloadExport(priceListId) {
  window.location = apiDownloadPricing({
    pricingListId: priceListId
  });
}

export function ExportButtons({ priceList, disabled }) {
  const dispatch = useContext(DispatchContext);
  const [prepareDownloadInitiated, setPrepareDownloadInitiated] = useState(
    false
  );

  useEffect(() => {
    if (prepareDownloadInitiated && priceList.export_status === "DONE") {
      setPrepareDownloadInitiated(false);
      downloadExport(priceList.id);
    }
    // run only on change of status
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceList.export_status]);

  return (
    <div className="flex justify-end">
      {priceList.export_status === "DONE" && !prepareDownloadInitiated && (
        <Button
          plain
          short
          icon={<Icons.Upload color="#445E9C" size="1.4rem" />}
          onClick={() => downloadExport(priceList.id)}
          className="p-3"
        >
          Stáhnout export
        </Button>
      )}
      <Button
        plain
        short
        icon={<Icons.RefreshCw color="#445E9C" size="1.4rem" />}
        onClick={() => {
          setPrepareDownloadInitiated(true);
          dispatch(prepareDownloads({ pricingListId: priceList.id }));
        }}
        className="p-3"
        loading={priceList.export_status === "PENDING"}
        loadingText="Exportuji"
        key={priceList.export_status}
        disabled={disabled}
      >
        {priceList.export_status === "DONE" || prepareDownloadInitiated
          ? "Přeexportovat"
          : "Exportovat"}
      </Button>
    </div>
  );
}

export default function PriceListCard({
  dispatch,
  priceList,
  onFinish,
  onLockToggle,
  openModal,
  width,
  disabledHeavyActions
}) {
  return (
    <div
      className={classNames(s.container, "shadow bg-white p-5 pb-2 mb-4")}
      style={{ minWidth: width, maxWidth: width }}
    >
      <div className="flex flex-row  items-center mb-5">
        <Text weight="600" size="1.125rem" className="pr-4">
          {priceList.title}
        </Text>
        <Text size="1.125rem">
          {format.date(priceList.from)} &ndash; {format.date(priceList.to)}
        </Text>
        <div className="flex-1" />
        <div className="">
          <Link to={`/price-list/${priceList.id}`}>
            <Button width="10rem" secondary>
              Otevřít
            </Button>
          </Link>
        </div>
      </div>

      <table className={s.cardTable}>
        <tbody>
          <tr>
            <td>
              <Text>Poslední úprava</Text>
            </td>
            <td>
              <Text weight="600">
                {format.date(priceList.last_row_update_at)}
              </Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Poslední export</Text>
            </td>
            <td>{format.date(priceList.last_export_at)}</td>
          </tr>
          <tr>
            <td>
              <Text>Historické období</Text>
            </td>
            <td>
              {format.date(lastYear(priceList.from))} -{" "}
              {format.date(lastYear(priceList.to))}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="flex justify-end">
        <ActionButton
          plain
          short
          icon={
            <Icons.Edit color="#445E9C" size="1.4rem" />
          }
          onClick={() => dispatch(openModal({ type: "EditPriceListModal", props: { priceList } }))}
          className="p-3"
        >
          Upravit
        </ActionButton>
        <ActionButton
          plain
          short
          icon={
            priceList.locked ? (
              <Icons.Lock color="#FFAF00" size="1.4rem" />
            ) : (
              <Icons.Unlock color="#445E9C" size="1.4rem" />
            )
          }
          onClick={onLockToggle}
          className="p-3"
          key={priceList.locked}
        >
          {priceList.locked ? "Odemknout" : "Uzamknout"}
        </ActionButton>
        <ExportButtons priceList={priceList} disabled={disabledHeavyActions} />
        <ActionButton
          plain
          short
          icon={<Icons.Check color="#009845" size="1.4rem" />}
          onClick={onFinish}
          className="p-3"
          loadingText="Dokončuji"
          disabled={disabledHeavyActions}
        >
          Dokončit
        </ActionButton>
      </div>
    </div>
  );
}
