import React, { useRef } from "react";
import Header from "../../components/Header";
import ScreenHeadline from "../../components/ui/ScreenHeadline/ScreenHeadline";
import { NewPriceListType } from "../../components/Modals/NewPriceList";
import {
  openModal,
  patchPriceList,
  requestPriceLists,
  requestSession
} from "../../store/actions";
import PriceListCard from "./PriceListCard";
import { ActionButton } from "../../components/ui/Button/Button";
import _ from "lodash"; // Fuuuuu
import FinishedPriceLists from "./FinishedPriceList";
import { useEffectOnce } from "../../library/hooks";
import { ImportDataSection } from "./Import";

function clearReloadTimeout(reloadTimerId) {
  clearTimeout(reloadTimerId.current);
  reloadTimerId.current = null;
}

function hasAnyPendingMutation(request) {
  return !!Object.values(request.mutations).find(({ pending }) => pending > 0);
}

export default function PriceLists({ state, dispatch }) {
  const { session, priceLists } = state.requests;
  const reloadTimerId = useRef();

  console.log({priceLists})

  useEffectOnce(() => {
    dispatch(requestPriceLists());
    dispatch(requestSession());
    return () => clearReloadTimeout(reloadTimerId);
  });

  const sessionLoaded = session.pending === 0 && session.data;
  const priceListsLoaded = priceLists.data;
  const isSomeExportPending = priceListsLoaded?.some(
    pl => pl.export_status === "PENDING"
  );
  const isHeavyProcessing =
    hasAnyPendingMutation(priceLists) || isSomeExportPending;
  const activePriceLists = priceListsLoaded
    ? _.orderBy(
        priceListsLoaded.filter(pl => !pl.finished),
        ["created_at"],
        ["desc"]
      )
    : [];
  const finishedPriceLists = priceListsLoaded
    ? _.orderBy(
        priceListsLoaded.filter(pl => pl.finished),
        ["created_at"],
        ["desc"]
      )
    : [];

  if (isSomeExportPending) {
    clearReloadTimeout(reloadTimerId);
    reloadTimerId.current = setTimeout(
      () => dispatch(requestPriceLists()),
      2600 // speedup
    );
  }

  return (
    <div>
      <Header
        user={session?.data?.user}
        priceLists={state.requests.priceLists.data}
      />
      <ImportDataSection keboola={session?.data?.keboolaLog} />
      <ScreenHeadline
        title="CENÍKY"
        loading={!sessionLoaded}
        actionButton={
          <ActionButton
            width="16rem"
            onClick={() =>
              dispatch(
                openModal({
                  type: NewPriceListType
                })
              )
            }
          >
            Vytvořit nový ceník
          </ActionButton>
        }
      />

      <div className="pt-10 pb-10 flex flex-col items-center">
        {sessionLoaded && priceListsLoaded && (
          <>
            {activePriceLists.map(priceList => (
              <PriceListCard
                key={priceList.id}
                width="45rem"
                priceList={priceList}
                onFinish={() => {
                  dispatch(patchPriceList(priceList.id, { finished: true }));
                }}
                dispatch={dispatch}
                openModal={openModal}
                disabledHeavyActions={isHeavyProcessing}
                onLockToggle={() =>
                  dispatch(
                    patchPriceList(priceList.id, {
                      locked: !priceList.locked
                    })
                  )
                }
              />
            ))}
            <FinishedPriceLists
              priceLists={finishedPriceLists}
              disableHeavyActions={isHeavyProcessing}
              openModal={openModal}
              dispatch={dispatch}
            />
          </>
        )}
      </div>
    </div>
  );
}
