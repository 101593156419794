import { submitNewPriceList, updatePriceListFormValue } from "../actions";
import { createReducer } from "redux-act";
import { success } from "redux-saga-requests";

export const initialState = {
  title: "",
  type: "state",
  from: "",
  to: ""
};

export default createReducer(
  {
    [updatePriceListFormValue]: (state, { kind, value }) => ({
      ...state,
      [kind]: value
    }),
    [success(submitNewPriceList)]: () => initialState
  },
  initialState
);
