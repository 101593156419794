import React, { useState } from "react";
import { closeModal, submitLimitBatchUpdate } from "../../store/actions";
import * as s from "./Modal.module.css";
import Text from "../ui/Text/Text";
import Button, { ActionButton } from "../ui/Button/Button";
import { useSelectLimitTypeOptions } from "../../screens/Limits/GroupTable";
import Select from "../ui/Select/Select";

export const LimitBatchUpdateType = "LimitBatchUpdate";

export function LimitBatchUpdate({ state: { modal }, dispatch }) {
  const { countryCode, countryName, limitTypes, limitIdFilter, resetLimitIdFilter } = modal.props;
  const [inProgress, setInProgress] = useState(false);
  const [selectedLimit, setSelectedLimit] = useState(null);
  const selectLimitTypeOptions = useSelectLimitTypeOptions(limitTypes);

  const handleSubmit = event => {
    if (event) event.preventDefault();
    setInProgress(true);
    dispatch(submitLimitBatchUpdate({ countryCode, limitId: selectedLimit, limitIdFilter }));
    resetLimitIdFilter();
  };

  return (
    <div>
      <div className={`flex flex-row items-center ${s.header}`}>
        <div className="flex-1">
          <Text size="1.25rem">Nastavit hromadně limit</Text>
        </div>
        <Button
          width="10rem"
          short
          secondary
          onClick={() => {
            dispatch(closeModal());
          }}
        >
          Zavřít
        </Button>
      </div>
      <form onSubmit={handleSubmit} className={s.content}>
        <div className="w-full flex flex-row justify-end mb-4">
          <Select
            value={selectedLimit}
            options={selectLimitTypeOptions}
            onChange={e => setSelectedLimit(e.target.value)}
          />
        </div>
        <div className="py-4">
          <ActionButton
            type="submit"
            disabled={inProgress}
            className={inProgress ? "inProgress" : ""}
          >
            {inProgress ? "Zpracovávám" : `Nastavit pro ${countryName}`}
          </ActionButton>
        </div>
      </form>
    </div>
  );
}
