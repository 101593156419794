import React from "react";
import PropTypes from "prop-types";
import * as s from "./Text.module.css";
import classNames from "classnames";
import { ClassNameType } from "../types";
import tinycolor from "tinycolor2";

Text.propTypes = {
  children: PropTypes.node,
  className: ClassNameType,
  size: PropTypes.string,
  color: PropTypes.string,
  light: PropTypes.bool,
  weight: PropTypes.string,
  nolink: PropTypes.bool,
  loading: PropTypes.bool
};

Text.defaultProps = {
  size: "1rem",
  children: ""
};

export default function Text({
  children,
  size,
  weight,
  color,
  light,
  className,
  loading,
  nolink,
  ...pass
}) {
  if (!Array.isArray(className)) {
    className = [className];
  }

  if (loading) {
    const style =
      light || (color && tinycolor(color).isDark())
        ? s.loadingLight
        : s.loadingDark;
    className.push(style);
  }

  if (light) {
    className.push(s.light);
  }

  return (
    <span
      className={classNames(s.text, className)}
      style={{ fontSize: size, fontWeight: weight, color }}
      {...pass}
    >
      {children || ""}
    </span>
  );
}
