import React from "react";
import {
  submitNewPriceList,
  updatePriceListFormValue,
  closeModal
} from "../../store/actions";
import * as s from "./NewPriceList.module.css";
import Button, { ActionButton } from "../ui/Button/Button";
import Text from "../ui/Text/Text";
import Input from "../ui/Input/Input";

export const NewPriceListType = "NewPriceList";

export function NewPriceList({ state: { priceListForm, requests }, dispatch }) {
  const handleChange = kind => ({ target: { value } }) => {
    dispatch(updatePriceListFormValue({ kind, value }));
  };
  const handleSubmit = event => {
    if (event) event.preventDefault();

    dispatch(submitNewPriceList(priceListForm));
  };
  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const errors = requests.newPriceList.error?.response?.data?.errors || {};

  return (
    <div>
      <div className={`flex flex-row items-center ${s.header}`}>
        <div className="flex-1">
          <Text size="1.25rem">Vytvořit nový ceník</Text>
        </div>
        <Button width="10rem" short secondary onClick={handleCloseModal}>
          Zavřít
        </Button>
      </div>
      <form onSubmit={handleSubmit} className={s.content}>
        <label className="py-2 block flex leading-loose">
          <div className="w-32 flex h-10 items-center">
            <Text weight="600">TYP CENÍKU</Text>
          </div>
          <div className="w-full">
            <select
              type="text"
              readOnly
              value={priceListForm.type}
              className={s.typeSelect}
            >
              <option value="state" onChange={handleChange("type")}>
                Státní
              </option>
            </select>
            <p className="m-0">
              {errors.type && (
                <span className="text-xs text-red-500">{errors.type.msg}</span>
              )}
            </p>
          </div>
        </label>
        <label className="py-2 block flex leading-loose">
          <div className="w-32 flex h-10 items-center">
            <Text weight="600">NÁZEV</Text>
          </div>
          <div className="w-full">
            <Input
              type="text"
              hasError={Boolean(errors.title)}
              value={priceListForm.title}
              onChange={handleChange("title")}
            />
            <p className="m-0">
              {errors.title && (
                <span className="text-xs text-red-500">{errors.title.msg}</span>
              )}
            </p>
          </div>
        </label>

        <label className="py-2 block flex leading-loose">
          <div className="w-32 flex h-10 items-center">
            <Text weight="600">OBDOBÍ</Text>
          </div>
          <div className="w-full flex flex-row ">
            <div className="flex-">
              <Input
                type="date"
                hasError={Boolean(errors.from)}
                onChange={handleChange("from")}
              />
              <p className="m-0">
                {errors.from && (
                  <span className="text-xs text-red-500">
                    {errors.from.msg}
                  </span>
                )}
              </p>
            </div>
            <div className="m-2" />
            <div className="flex-">
              <Input
                type="date"
                hasError={Boolean(errors.to)}
                onChange={handleChange("to")}
              />
              <p className="m-0">
                {errors.to && (
                  <span className="text-xs text-red-500">{errors.to.msg}</span>
                )}
              </p>
            </div>
          </div>
        </label>
        <hr />
        <div className="py-4">
          <ActionButton
            type="submit"
            onClick={handleSubmit}
            key={Object.keys(errors).length}
            loadingText="Vytvářím nový ceník"
          >
            Vytvořit nový ceník
          </ActionButton>
        </div>
        <div className="pb-2 text-center">
          <Text>Nově vytvořený ceník nebude okamžitě platný</Text>
        </div>
      </form>
    </div>
  );
}
