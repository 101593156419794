/**
 * Returns dd/mm/yyyy
 *
 * @param {string|Date} givenDate
 * @returns {string}
 */
import moment from "moment";

export const date = givenDate => {
  if (!givenDate) return;

  return moment(givenDate).format("D/M/Y");
};

export const count = giveNumber => {
  if (typeof giveNumber !== "number") return;

  return giveNumber.toLocaleString();
};

export const number = (giveNumber, placeholder = "-") => {
  if (typeof giveNumber !== "number") return;

  let number = Number(giveNumber);
  number = number === -0 ? 0 : number;
  return number || number === 0 ? number.toLocaleString() : placeholder;
};

export const percent = giveNumber => {
  if (typeof giveNumber !== "number") return;

  return giveNumber * 100;
};
