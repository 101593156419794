import React, { useState } from "react";
import classNames from "classnames";
import * as s from "./PLCard.module.css";
import Text from "../../components/ui/Text/Text";
import * as format from "../../library/format";
import Button from "../../components/ui/Button/Button";
import { Upload } from "react-feather";
import { Link } from "@reach/router";
import { apiExportPricing, pricingUrl } from "../../library/routes";
import { lastYear } from "../../library/date";

export default function PLCard({ title, priceListDetail, country }) {
  const [isOpened, setIsOpened] = useState(false);
  const priceListId = priceListDetail.id;
  const countryCode = country.code;
  return (
    <div className={classNames(s.container, "shadow")}>
      <div className={s.header}>
        <div className="flex-1 truncate">
          {countryCode !== 'ALL'
            ? (
              <Link className={s.title} to={pricingUrl(priceListId, countryCode)}>
                {title}
              </Link>
            )
            : <strong>{title}</strong>
          }
        </div>
        {countryCode !== 'ALL' && (
          <div className={s.export}>
            <Text color="#81899B">{format.date(country.last_export_at)}</Text>
            <Button
              className={s.button}
              plain
              icon={<Upload color="#445E9C" />}
              onClick={() =>
                (window.location = apiExportPricing(priceListId, countryCode))
              }
            />
          </div>
        )}
      </div>
      <ul className={s.items}>
        <li className={s.item}>
          <Text size="0.825rem">Turnover plan CM</Text>
          <Text size="0.825rem" weight="600">
            {format.number(country.sell_plan_amount)} Kč
          </Text>
        </li>
        <li className={s.item}>
          <Text size="0.825rem">Turnover plan MKT</Text>
          <Text size="0.825rem" weight="600">
            {format.number(Math.round(country.sell_plan_mkt))} Kč
          </Text>
        </li>
        <li className={s.item}>
          <Text size="0.825rem">Margin (%)</Text>
          <Text size="0.825rem" weight="600">
            {country.margin.toFixed(2)}%
          </Text>
        </li>
        <li className={s.item}>
          <Text size="0.825rem">Margin (CZK)</Text>
          <Text size="0.825rem" weight="600">
            {format.number(Math.round(country.margin_absolute))} Kč
          </Text>
        </li>
        <li className={s.item}>
          <Text size="0.825rem">
            History (
            {`${format.date(lastYear(priceListDetail.from))} - ${format.date(
              lastYear(priceListDetail.to)
            )}`}
            )
          </Text>
          <Text
            size="0.825rem"
            weight="600"
            className={s.expandDetails}
            onClick={() => setIsOpened(!isOpened)}
          >
            {isOpened ? "Hide detail" : "See detail"}
          </Text>
        </li>
        {isOpened && (
          <>
            <li className={s.item}>
              <Text size="0.825rem">Turnover total</Text>
              <Text size="0.825rem" weight="600">
                {format.number(country.sell_history_amount)} Kč
              </Text>
            </li>
            <li className={s.item}>
              <Text size="0.825rem">Pcs total</Text>
              <Text size="0.825rem" weight="600">
                {format.number(country.sell_history_pieces)} Ks
              </Text>
            </li>
            <li className={s.item}>
              <Text size="0.825rem">Margin (%)</Text>
              <Text size="0.825rem" weight="600">
                {country.sell_history_mbr.toFixed(2)}%
              </Text>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
