import React, { useEffect } from "react";
import idx from "idx";
import { DollarSign } from "react-feather";

import Header from "../../components/Header";
import { ActionButton } from "../../components/ui/Button/Button";
import ScreenHeadline from "../../components/ui/ScreenHeadline/ScreenHeadline";

import { recountKPI, requestPriceListDetail } from "../../store/actions";
import { pricingUrlCurrency } from "../../library/routes";
import * as format from "../../library/format";
import { lastYear } from "../../library/date";

import PLCard from "./PLCard";

import * as s from "./index.module.css";

const priceListTypeToTitle = type => {
  switch (type) {
    case "state":
      return "Státní";
    default:
      return "";
  }
};

export default function PriceListDashboard({ state, dispatch, priceListId }) {
  const session = state.requests.session;
  const priceListDetail = state.requests.priceListsById[priceListId]?.data;
  const priceListLoading =
    state.requests.priceListsById[priceListId]?.fetching ?? false;

  useEffect(() => {
    dispatch(requestPriceListDetail(priceListId, { kpi: true }));
  }, [dispatch, priceListId]);

  const headline = {
    title: `${priceListTypeToTitle(
      priceListDetail && priceListDetail.type
    )} ceníky`,
    subtitle:
      priceListDetail &&
      `HISTORICKÁ DATA ${format.date(
        lastYear(priceListDetail.from)
      )} - ${format.date(lastYear(priceListDetail.to))}`
  };

  return (
    <div>
      <Header
        user={idx(session, _ => _.data.user)}
        priceLists={state.requests.priceLists.data}
      />
      <ScreenHeadline
        title={headline.title}
        loading={priceListLoading}
        subtitle={headline.subtitle}
        actionButton={
          <ActionButton
            onClick={() => dispatch(recountKPI(priceListId))}
            key={priceListLoading}
            loadingText="Přepočítávám"
          >
            Přepočítat KPI
          </ActionButton>
        }
        leftActionButton={
          <ActionButton
            path={pricingUrlCurrency(priceListId)}
          >
            <div className="flex items-center">
              <DollarSign color="#fff" size={20} style={{marginRight: '5px', display: 'block'}} />
              <span>Zobrazit v měně...</span>
            </div>
          </ActionButton>
        }
      />
      {priceListDetail?.countries && (
        <div className={s.grid}>
          {priceListDetail.countries.map(country => (
            <PLCard
              key={country.code}
              title={country.name}
              priceListDetail={priceListDetail}
              country={country}
            />
          ))}
        </div>
      )}
    </div>
  );
}
