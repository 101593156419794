import React, { useState } from "react";
import { logout } from "../library/auth";
import { getFirstAndLastInitials } from "../library/user";
import * as s from "./UserMenu.module.css";

export default function UserMenu({ userName }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={s.menu}
      tabIndex="0"
      onBlur={() => setIsOpen(false)}
      onClick={() => setIsOpen(open => !open)}
    >
      <div className={s.menuIcon}>{getFirstAndLastInitials(userName)}</div>
      {isOpen && (
        <div className={s.menuContent}>
          <div className={s.menuItem}>
            <strong>{userName}</strong>
          </div>
          <div className={s.menuItem} onClick={() => logout()}>
            Log out
          </div>
        </div>
      )}
    </div>
  );
}
