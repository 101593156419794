import {
  closeModal,
  openModal,
  submitNewPriceList,
  submitNewLimit,
  createNewPricingGroup,
  updatePricingGroup
} from "../actions";
import { createReducer } from "redux-act";
import { success } from "redux-saga-requests";

export const initialState = {
  open: false,
  type: null,
  size: null,
  props: null
};

export default createReducer(
  {
    // We complete ignore previous state here
    [openModal]: (_, { type, size, props }) => ({
      open: true,
      type,
      size,
      props
    }),
    [closeModal]: () => initialState,
    [success(submitNewPriceList)]: () => initialState,
    [success(submitNewLimit)]: () => initialState,
    [success(createNewPricingGroup)]: () => initialState,
    [success(updatePricingGroup)]: () => initialState,
  },
  initialState
);
