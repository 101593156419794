import React, { useMemo } from "react";
import classNames from "classnames";
import * as s from "./GroupTable.module.css";
import Text from "../../components/ui/Text/Text";
import IconColorful from "../../components/ui/IconColorful";
import Select, { ActionSelect } from "../../components/ui/Select/Select";

const limitTableRows = count =>
  [...Array(Number(count) + 1)].map((_, index) => index + 1);

export function useSelectLimitTypeOptions(limits) {
  return useMemo(() => {
    const options = (limits || []).map(limit => ({
      value: limit.id,
      title: limit.title
    }));
    options.unshift({ title: Select.NO_VALUE, value: null });
    return options;
  }, [limits]);
}

export default function GroupTable({
  groups,
  limitValuesCount = 0,
  limits,
  limitQuery,
  onLimitTypeChange
}) {
  const handleLimitTypeChange = group => ({ target: { value } }) => {
    onLimitTypeChange({ group, value });
  };
  const selectLimitTypeOptions = useSelectLimitTypeOptions(limits);

  const pricingGroupLimitRange = (groupLimit, index) => {
    const limit = (limits || []).find(
      limit => groupLimit && limit.id === groupLimit.limit_id
    );
    if (!groupLimit || !limit || limit.values.length < index) {
      return "-";
    }
    const minimum = Number(limit.values[index - 1]) || 0;
    const maximum = Number(limit.values[index]);

    return maximum ? `${minimum + 1} - ${maximum}` : minimum + 1 + " >";
  };

  if (limitQuery) {
    const queriedLimit = limits.find(l => `${l.id}` === `${limitQuery}`)
    if (queriedLimit) {
      limitValuesCount = queriedLimit.values.length
    }
  }

  return (
    <div className={classNames(s.container)}>
      <table className={s.table}>
        <thead>
          <tr>
            <th>
              <Text size="0.875rem" weight="600">
                KOM.
              </Text>
            </th>
            <th>
              <Text size="0.875rem" weight="600">
                CENÍKOVÁ SKUPINA
              </Text>
            </th>
            {limitTableRows(limitValuesCount).map((title, index) => (
              <th key={index} className={s.limitHeadline}>
                <Text color="#fff">{title}</Text>
              </th>
            ))}
            <th>
              <Text size="0.875rem" weight="600">
                TYP LIMITU
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {groups.map(group => (
            <tr key={group.id}>
              <td>
                <div className={s.column}>
                  <IconColorful colorful={group.is_colorful} />
                  <Text size="0.875rem">{group.commodity_code}</Text>
                </div>
              </td>
              <td>
                <Text size="0.875rem">{group.title}</Text>
              </td>
              {limitTableRows(limitValuesCount).map((_, index) => (
                <td key={index}>
                  <div className={classNames(s.column, s.limitColumn)}>
                    <Text size="0.875rem" className="whitespace-no-wrap">
                      {pricingGroupLimitRange(
                        group.pricing_group_setting,
                        index
                      )}
                    </Text>
                  </div>
                </td>
              ))}
              <td>
                <ActionSelect
                  className={s.selectLimit}
                  noContainer
                  value={
                    group?.pricing_group_setting?.limit_id || Select.NO_VALUE
                  }
                  options={selectLimitTypeOptions}
                  onChange={handleLimitTypeChange(group)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
