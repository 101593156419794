import { takeEvery, put } from "redux-saga/effects";
import { getExchangeRates, updateExchangeRate } from "../store/actions";
import { success } from "redux-saga-requests";

export function* handleUpdateExchangeRate() {
  yield put(getExchangeRates());
}

export default function* exchangeRatesSaga() {
  yield takeEvery(success(updateExchangeRate), handleUpdateExchangeRate);
}
