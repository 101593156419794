import React, { useContext, useEffect, useState, useMemo } from "react";

import Input from "../../components/ui/Input/Input";
import Select from "../../components/ui/Select/Select";
import { ActionButton } from "../../components/ui/Button/Button";
import { Loader, Check } from "react-feather";

import { updateCountrySettings } from "../../store/actions";
import { DispatchContext } from "../../App";
import {
  translateFromLevel,
  indexToChar
} from "./Configuration";

export default function CountrySettings({ countryCode, extraLevels, configurations, requests }) {

  const dispatch = useContext(DispatchContext);

  const sourceOptions = useMemo(() => {
    if (configurations?.length) {
      const options = []
      let width = 0;
      let height = 0;
      configurations.forEach(c => {
        if (c.width > width) width = c.width
        if (c.height > height) height = c.height
      })
      for (var h = 1;h <= height;h++) {
        for (var w = 1;w <= width;w++) {
          const l = `${indexToChar(h)}${w}`
          options.push({ value: l, title: l })
        }
      }
      return options
    }
    return []
  }, [configurations])

  const LEVELS = ['E', 'K'];
  function Level() {
    this.sourceStr = 'A1'
    this.operator = 'add'
    this.constant = ''
  }

  const deserialize = level => {
    if (level?.source?.x && level?.source?.y) {
      level.sourceStr = `${indexToChar(level.source.y)}${level.source.x}`
    } else {
      level.sourceStr = ''
    }
    return level
  }

  const [model, setModel] = useState({
    E: extraLevels?.E?.operator ? deserialize(extraLevels.E) : new Level(),
    K: extraLevels?.K?.operator ? deserialize(extraLevels.K) : new Level(),
  })
  useEffect(() => {
    setModel({
      E: extraLevels?.E?.operator ? deserialize(extraLevels.E) : new Level(),
      K: extraLevels?.K?.operator ? deserialize(extraLevels.K) : new Level(),
    })
  }, [extraLevels])

  const submit = e => {
    e.preventDefault()

    const payload = window.structuredClone(model);
    LEVELS.forEach(level => {
      if (!model[level].constant) {
        payload[level] = null
      } else {
        payload[level].source = {
          x: payload[level].sourceStr[1],
          y: parseInt(translateFromLevel(payload[level].sourceStr[0]), 10),
        }
        delete payload[level].sourceStr
      }
    })
    dispatch(
      updateCountrySettings({
        countryCode,
        E: payload.E,
        K: payload.K,
      })
    );
  };

  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (!requests.pending && success === 0) {
      setSuccess(1);
      setTimeout(() => {
        setSuccess(false);
      }, 2000)
    }
    if (requests.pending) {
      setSuccess(0);
    }
  }, [requests, success])

  const iconStyle = {
    margin: '-3px 0',
  };

  return (
    <form onSubmit={submit} style={{ marginBottom: '1rem' }}>
      {LEVELS.map(level => (
        <div key={level} style={{display: 'flex', alignItems: 'center', marginBottom: '.5rem' }}>
          {level}&nbsp;&nbsp;
          <Select
            options={sourceOptions}
            value={model[level].sourceStr}
            onChange={e => {
              setModel({ ...model, [level]: { ...model[level], sourceStr: e.target.value }});
            }}
          />
          <Select
            options={[
              { value: 'add', title: '+' },
              { value: 'multiply', title: '*' }
            ]}
            value={model[level].operator}
            onChange={e => {
              setModel({ ...model, [level]: { ...model[level], operator: e.target.value }});
            }}
          />
          <Input
            type="number"
            step="0.01"
            min={0}
            placeholder="Konstanta"
            value={model[level].constant}
            onChange={e => {
              const val = e.target.value ? parseFloat(e.target.value) : e.target.value
              setModel({ ...model, [level]: { ...model[level], constant: val }});
            }}
          />
        </div>
      ))}
      <ActionButton
        secondary
        type='submit'
      >
        <div style={{ display: 'flex', gridGap: '1rem', justifyContent: 'center' }}>
          Uložit
          {requests?.pending ? <Loader style={iconStyle} /> : null}
          {success ? <Check style={iconStyle} color='var(--c-primary-base)' /> : null}
        </div>
      </ActionButton>
    </form>
  )
}