import { takeEvery, put } from "redux-saga/effects";
import {
  closeModal,
  updateMatrix,
  deleteMatrix,
  requestMatrixConfigurations
} from "../store/actions";
import { success } from "redux-saga-requests";

export function* handleMatrixUpdate({ meta }) {
  yield put(requestMatrixConfigurations(meta));
}
export function* handleMatrixUpdateAndCloseModal({ meta }) {
  yield put(requestMatrixConfigurations(meta));
  yield put(closeModal());
}

export default function* matrixSaga() {
  yield takeEvery(success(updateMatrix), handleMatrixUpdate);
  yield takeEvery(success(deleteMatrix), handleMatrixUpdateAndCloseModal);
}
