import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import * as s from "./ScreenHeadline.module.css";

ScreenHeadline.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
  actionButton: PropTypes.element
};

export default function ScreenHeadline({
  title,
  loading,
  subtitle,
  actionButton,
  leftActionButton
}) {
  return (
    <div className={classNames(s.container)}>
      <div className={s.left}>{leftActionButton}</div>
      <div className={s.middle}>
        <h1 className={classNames(s.title, loading && s.loading)}>
          {title.toUpperCase()}
        </h1>

        <span
          className={classNames(s.subtitle, subtitle && loading && s.loading)}
        >
          {subtitle && subtitle.toString()}
        </span>
      </div>
      <div className={s.right}>{actionButton && actionButton}</div>
    </div>
  );
}
