import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as s from "./Button.module.css";
import classNames from "classnames";
import { ClassNameType } from "../types";
import Text from "../Text/Text";
import { Link } from "@reach/router";
import { useFreshState } from "../../../library/hooks";
import { ReadOnlyContext } from "../../../App";

Button.propTypes = {
  className: ClassNameType,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  secondary: PropTypes.bool,
  width: PropTypes.string,
  short: PropTypes.bool,
  plain: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  path: PropTypes.string,
  hasLoading: PropTypes.bool,
  loading: PropTypes.bool,
  loadingText: PropTypes.string
};

export default function Button({
  onClick,
  children,
  secondary,
  width,
  height,
  className,
  short,
  plain,
  icon,
  disabled,
  loading = false,
  loadingText,
  path,
  size,
  bgColor,
  ...pass
}) {
  const hasLoading = loadingText ?? pass.hasLoading;
  if (!Array.isArray(className)) {
    className = [className];
  }

  const timeoutRef = useRef();
  const [isLoading, setIsLoading] = useFreshState(loading);

  useEffect(() => {
    if (hasLoading && isLoading) {
      timeoutRef.current = setTimeout(() => {
        setIsLoading(false);
      }, 120_000);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  });

  const button = (
    <button
      onClick={e => {
        onClick && onClick(e);
        hasLoading && setIsLoading(true);
      }}
      style={{ width, height, backgroundColor: bgColor }}
      className={classNames(
        s.button,
        secondary && s.secondary,
        short && s.short,
        plain && s.plain,
        icon && "flex flex-row items-center",
        disabled && s.disabled,
        ...className
      )}
      disabled={disabled || (hasLoading && isLoading)}
      {...pass}
    >
      {icon && (
        <div className={`${children && s.hasContent} flex items-center`}>
          {icon}
        </div>
      )}
      {children && (
        <Text
          weight="600"
          light={!plain}
          color={disabled ? "#A1AFCD" : undefined}
          size={size ?? "inherit"}
          className={classNames({
            inProgress: isLoading && loadingText !== false
          })}
        >
          {isLoading && loadingText ? loadingText : children}
        </Text>
      )}
    </button>
  );

  return path ? <Link to={path}>{button}</Link> : button;
}

/**
 * Use for mutating actions or buttons leading to some not read only action.
 * If use is READER, button will not be shown.
 */
export function ActionButton(props) {
  const readOnly = useContext(ReadOnlyContext);
  if (readOnly) return null;

  return <Button disabled={readOnly} {...props} />;
}
