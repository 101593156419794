import React, { useEffect, useMemo, useState } from "react";
import { navigate } from "@reach/router";
import {
  assignLimitToPricingGroup,
  openModal,
  requestCountries,
  requestLimitTypes,
  requestPricingGroupLimits,
  requestMatrixConfigurations
} from "../../store/actions";
import Header from "../../components/Header";
import ScreenHeadline from "../../components/ui/ScreenHeadline/ScreenHeadline";
import idx from "idx";
import { ActionButton } from "../../components/ui/Button/Button";
import Text from "../../components/ui/Text/Text";
import Select from "../../components/ui/Select/Select";
import Input from "../../components/ui/Input/Input";
import GroupTable from "./GroupTable";
import { NewLimitType } from "../../components/Modals/NewLimit";
import qs from "querystring";
import debounce from "../../library/debounce";
import { useDataRequests } from "../../library/react";
import { LimitBatchUpdateType } from "../../components/Modals/LimitBatchUpdate";

// const { REACT_APP_LIMIT_VALUES_COUNT: LIMIT_VALUES_COUNT } = process.env;

const mapStateToProps = (state, countryCode) => {
  const groupLimits = state.requests.pricingGroupLimits[countryCode] || {};

  return {
    session: state.requests.session,
    countries: state.requests.countries.data,
    countriesLoading: state.requests.countries.pending > 0,
    pricingGroupLimits: groupLimits.data,
    pricingGroupLimitsLoading: groupLimits.fetching,
    matrixConfigurations: state.matrixConfigurationForms,
    limitTypes: state.requests.limitTypes.data,
    limitTypesLoading: state.requests.limitTypes.pending > 0
  };
};

export default function Limits({ state, dispatch, countryCode, location }) {
  const [limitValueCount, setLimitValueCount] = useState(0);
  const {
    session,
    countries,
    countriesLoading,
    pricingGroupLimits,
    pricingGroupLimitsLoading,
    matrixConfigurations,
    limitTypes = [],
    limitTypesLoading
  } = mapStateToProps(state, countryCode);

  const isAnythingLoading =
    countriesLoading ||
    pricingGroupLimitsLoading ||
    limitTypesLoading ||
    !countryCode;

  /*
   * Select first country if no one selected yet
   */
  const firstCountry = countries ? countries[0] : undefined;
  useEffect(() => {
    if (!countryCode && firstCountry) {
      navigate("/limits/" + firstCountry.code, { replace: true });
    }
  }, [countryCode, firstCountry]);

  /*
   * Search propagations throw URL search change to dispatched requests
   */

   const searchQuery = qs.parse(location.search.slice(1)).q;
   const limitQuery = qs.parse(location.search.slice(1)).limit;

  const propagateSearchQueryToURL = debounce(q => {
    const params = new URLSearchParams(
      "q=" + encodeURI(q)
      + (limitQuery ? '&limit=' + encodeURI(limitQuery) : '')
    ).toString();
    const query = params ? `?${params}` : ''
    navigate(query);
  }, 300);
  const handleSearch = ({ target: { value: query } }) => {
    propagateSearchQueryToURL(query);
  };
  const handleLimitFilterChange = ({ target: { value: limit } }) => {
    if (limit === '') limit = null;
    const params = new URLSearchParams(
      (searchQuery ? "q=" + encodeURI(searchQuery) : '')
      + (limit ? "&limit=" + encodeURI(limit) : '')
    ).toString();
    const query = params ? `?${params}` : '';
    navigate(`/limits/${countryCode}${query}`);
  };

  /*
   * Request data
   */
  useDataRequests(
    { state, dispatch },
    [
      () => requestCountries(),
      () => requestLimitTypes(),
      () =>
        countryCode && requestPricingGroupLimits({ countryCode, searchQuery, limitQuery }),
      // matrix configurations are needed in new limit modal
      () => countryCode && requestMatrixConfigurations({ countryCode })
    ],
    [location.pathname, location.search]
  );

  useEffect(() => {
    if (matrixConfigurations.localCopy[countryCode]?.length) {
      const largestLimitValue = matrixConfigurations.localCopy[countryCode].reduce((acc, mc) => {
        const value = (mc.matrix?.[0]?.length || 1) - 1;
        return value > acc ? value : acc;
      }, 0);
      setLimitValueCount(largestLimitValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrixConfigurations.localCopy?.[countryCode]?.length]);

  /*
   * Memory Group Table to increase performance
   */

  const memoizedGroupTable = useMemo(() => {
    const handlePricingGroupLimitTypeChange = ({ group, value }) => {
      dispatch(
        assignLimitToPricingGroup({
          groupId: group.id,
          limitId: value,
          countryCode
        })
      );
    };

    return (
      <GroupTable
        groups={pricingGroupLimits}
        limitValuesCount={limitValueCount}
        limitQuery={limitQuery}
        limits={limitTypes}
        onLimitTypeChange={handlePricingGroupLimitTypeChange}
      />
    );
  }, [dispatch, countryCode, pricingGroupLimits, limitTypes, limitValueCount, limitQuery]);

  return (
    <>
      <Header
        user={idx(session, _ => _.data.user)}
        priceLists={state.requests.priceLists.data}
      />
      <div style={{ maxWidth: "75rem", margin: "0 auto" }}>
        <ScreenHeadline
          className="mb-10"
          title="NASTAVENÍ LIMITŮ"
          loading={isAnythingLoading}
          actionButton={
            <div className="flex">
              <ActionButton
                secondary
                size="0.875rem"
                className="mr-2"
                onClick={() => {
                  dispatch(
                    openModal({
                      type: LimitBatchUpdateType,
                      props: {
                        countryCode,
                        countryName: countries.find(
                          ({ code }) => code === countryCode
                        )?.name,
                        limitIdFilter: limitQuery,
                        limitTypes,
                        resetLimitIdFilter: () => handleLimitFilterChange({ target: { value: '' } })
                      }
                    })
                  );
                }}
              >
                Nastavit limit hromadně
              </ActionButton>

              <ActionButton
                size="0.875rem"
                onClick={() => {
                  dispatch(
                    openModal({
                      type: NewLimitType,
                      props: {
                        countryCode,
                      },
                    })
                  );
                }}
              >
                Vytvořit nový limit
              </ActionButton>
            </div>
          }
        />
        <div className="h-10" />

        <div className="bg-white m-4">
          <div className="flex p-1">
            <label className="flex items-center py-2 px-3">
              <Text size="0.875rem" weight="600" className="mr-2">
                STÁT
              </Text>
              <Select
                onChange={({ target: { value } }) => {
                  navigate("/limits/" + value);
                }}
                style={{ width: "17rem" }}
                value={countryCode}
                options={(countries || []).map(country => ({
                  value: country.code,
                  title: country.name
                }))}
              />
            </label>
            <label className="flex items-center py-2 px-3">
              <Text size="0.875rem" weight="600" className="mr-2">
                TYP LIMITU
              </Text>
              <Select
                onChange={handleLimitFilterChange}
                style={{ width: "17rem" }}
                value={limitQuery}
                options={[
                  { value: null, title: '' },
                  { value: '0', title: 'N/A' },
                  ...(limitTypes || []).map(item => ({
                    value: `${item.id}`,
                    title: item.title
                  }))
                ]}
              />
            </label>
            <div className="flex-1" />
            <label className="flex items-center py-2 px-3">
              <Input
                type="search"
                // Do not control this input to increase performance (visual feedback) PLUS
                // searchQuery is now stored only in URL, and we wont to change URL every keypress
                // value={searchQuery}
                defaultValue={searchQuery}
                style={{ width: "17rem" }}
                placeholder="Vyhledat ..."
                onChange={handleSearch}
              />
            </label>
          </div>
          <div className="">
            {!pricingGroupLimits || !pricingGroupLimits.length ? (
              <div className="p-10 text-center">
                <em>
                  <Text color="#D3D8E6">
                    {isAnythingLoading ? "Načítá se..." : "Kde nic tu nic."}
                  </Text>
                </em>
              </div>
            ) : (
              memoizedGroupTable
            )}
          </div>
        </div>
      </div>
    </>
  );
}
