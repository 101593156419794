/**
 * All application actions
 *
 * powered by library [redux-act](https://github.com/pauldijou/redux-act)
 *  which decreases classic Redux boilerplate hell. contants not needed anymore.
 */
import { createAction } from "redux-act";
import * as qs from "query-string";

/*
 * Application basic
 */
export const ping = createAction("ping, the test action");

export const initializeApplication = createAction("initialize app");

export const setServerError = createAction("set server error");
export const setAccessToken = createAction("set access token");
export const logout = createAction("logout");

/*
 *  Forms
 */

export const updatePriceListFormValue = createAction("update form value");

export const updateMatrixConfigurationValue = createAction("matrix val update");
export const updateMatrixLeadingValue = createAction(
  "matrix leading value update"
);
export const prepareNewMatrixConfiguration = createAction(
  "prepare new matrix "
);

export const updateLimitFormValue = createAction("update limit form value");

export const rewriteAutoAssignSuggestion = createAction(
  "rewrite auto asign suggestion"
);
export const toggleAutoAssignSuggestionIgnoreFlag = createAction(
  "toggle auto assign suggestion ignore flag"
);

/*
 *  Modals
 */

export const openModal = createAction("open modal");
export const closeModal = createAction("close modal");

/*
 * Non request actions
 */

export const changeUnassignedNomenclaturesSearch = createAction(
  "change unassigned nomenclatures search",
  search => ({ search })
);

export const changeNomenclaturesForPricingGroupSearch = createAction(
  "change search for nomenclatures for pricing group",
  search => ({ search })
);

export const selectCommodityForAutoAssign = createAction(
  "select commodity for auto assign",
  commodityCode => ({
    commodityCode
  })
);

/*
 *  Data request actions
 */

export const requestSession = createAction(
  "request session",
  () => ({
    request: {
      url: "/authentication/session"
    }
  }),
  () => ({
    takeLatest: true,
    abortOn: logout
  })
);

export const requestSignIn = createAction(
  "request sign-in",
  ({ email, password }) => ({
    request: {
      url: "/authentication/login",
      method: "post",
      data: { email, password }
    }
  })
);

export const submitNewPriceList = createAction(
  "submit new price list",
  ({ type, title, from, to }) => ({
    request: {
      url: "/price-list",
      method: "post",
      data: { type, title, from, to }
    }
  })
);

export const patchPriceList = createAction(
  "finish pricelist",
  (id, { finished, locked, title } = {}) => ({
    request: {
      url: `/price-list/${id}`,
      method: "patch",
      data: { finished, locked, title }
    }
  })
);

export const deletePriceList = createAction(
  "delete pricelist",
  (id) => ({
    request: {
      url: `/price-list/${id}`,
      method: "delete",
    }
  })
);

export const requestPriceLists = createAction(
  "request price lists collection",
  () => ({
    request: {
      url: `/price-list`
    }
  })
);

export const requestPriceListDetail = createAction(
  "request pricelist",
  (id, opts = {}) => {
    const params = qs.stringify(opts);
    return {
      request: {
        url: `/price-list/${id}?${params}`
      }
    };
  },
  id => {
    return { id };
  }
);

export const requestCountries = createAction("request countries", () => ({
  request: {
    url: "/country"
  }
}));

export const requestMatrixConfigurations = createAction(
  "request matrix configuration",
  ({ countryCode } = {}) => ({
    request: {
      url: `/configuration-matrix${countryCode !== '$__ALL__$' ? `?country_code=${countryCode}` : ''}`
    }
  }),
  ({ countryCode } = {}) => ({
    countryCode
  })
);

export const submitNewMatrixConfiguration = createAction(
  "submit new matrix configuration",
  ({ country_code, name, matrix }) => ({
    request: {
      url: "/configuration-matrix",
      method: "post",
      data: {
        country_code,
        name,
        matrix,
        width: matrix[0].length,
        height: matrix.length
      }
    }
  }),
  ({ country_code }) => ({ countryCode: country_code })
);

export const patchMatrixConfiguration = createAction(
  "patch new matrix configuration",
  ({ id, country_code, name, matrix, leading_x = 1, leading_y = 1 }) => ({
    request: {
      url: `/configuration-matrix/${id}`,
      method: "patch",
      data: {
        id,
        country_code,
        name,
        matrix,
        leading_x,
        leading_y
      }
    }
  }),
  ({ country_code, id, name }) => ({ countryCode: country_code, id, name })
);
export const patchMatrixConfigurationLeadingColumn = createAction(
  "patch matrix configuration leading column",
  ({ id, countryCode, x, y }) => ({
    request: {
      url: `/configuration-matrix/${id}`,
      method: "patch",
      data: {
        id,
        country_code: countryCode,
        leading_x: x,
        leading_y: y
      }
    }
  }),
  ({ countryCode, id, x, y }) => ({ countryCode, id, x, y })
);

export const requestPricingGroupLimits = createAction(
  "request pricing group limits",
  ({ countryCode, searchQuery, limitQuery }) => {
    const params = qs.stringify({ country: countryCode, search: searchQuery, limitId: limitQuery || undefined });
    return {
      request: {
        url: `/pricing-group-limit?${params}`
      },
      meta: {
        takeLatest: true,
        abortOn: requestPricingGroupLimits
      }
    };
  },
  ({ countryCode }) => ({ countryCode })
);

export const requestLimitTypes = createAction("request limit types", () => ({
  request: {
    url: `/limit`
  }
}));

export const submitNewLimit = createAction(
  "submit new limit",
  ({ title, values }) => ({
    request: {
      url: "/limit",
      method: "post",
      data: { title, values }
    }
  })
);

export const assignLimitToPricingGroup = createAction(
  "assign limit to pricing group",
  ({ groupId, limitId, countryCode }) => ({
    request: {
      url: `/pricing-group/${groupId}/limit`,
      method: "put",
      data: { limit_id: Number(limitId), country_code: countryCode }
    }
  }),
  meta => meta // pass all params as meta (extra reducers will need ot)
);

export const requestPricingGroups = createAction(
  "request pricing groups",
  ({ search = "" } = {}) => ({
    request: {
      url: `/pricing-group?search=${search}`
    }
  })
);

export const requestNomenclaturesForPricingGroup = createAction(
  "request nomenclatures for pricing group",
  ({ pricingGroupId, search = "" }) => {
    return {
      request: {
        url: `/nomenclature?pricing_group_id=${pricingGroupId}&search=${search}`
      }
    };
  }
);

export const requestUnassignedNomenclatures = createAction(
  "request unassigned nomenclatures",
  ({ search = "" } = {}) => {
    return {
      request: {
        url: `/nomenclature?not_associated=true&search=${search}`
      }
    };
  }
);

export const assignNomenclatureToPricingGroup = createAction(
  "update pricing group to include the nomenclature",
  (nomenclatureId, pricingGroupId) => {
    return {
      request: {
        url: `/pricing-group/${pricingGroupId}/nomenclature`,
        method: "put",
        data: { nomenclature_id: nomenclatureId }
      }
    };
  },
  (_, pricingGroupId, searches) => ({ ...searches, pricingGroupId })
);

export const removeNomenclatureFromPricingGroup = createAction(
  "delete nomenclature from pricing group",
  (nomenclatureId, pricingGroupId) => {
    return {
      request: {
        url: `/pricing-group/${pricingGroupId}/nomenclature/${nomenclatureId}`,
        method: "delete"
      }
    };
  },
  (_, pricingGroupId, searches) => ({ ...searches, pricingGroupId })
);

export const createNewPricingGroup = createAction(
  "create new pricing group",
  ({ title, isColorful, commodity }) => ({
    request: {
      url: "/pricing-group",
      method: "post",
      data: { title, is_colorful: isColorful, commodity_code: commodity }
    }
  })
);

export const updatePricingGroup = createAction(
  "update pricing group",
  ({ id, title, isColorful, commodity }) => ({
    request: {
      url: `/pricing-group/${id}`,
      method: "patch",
      data: { title, is_colorful: isColorful, commodity_code: commodity }
    }
  })
);

export const requestCommodities = createAction(
  "request commodities",
  ({ search = "" } = {}) => ({
    request: {
      url: `/commodity?search=${search}`
    }
  })
);

export const requestFilters = createAction(
  "request filters",
  ({ priceListId, countryCode } = {}) => ({
    request: {
      url: `/pricing-list/${priceListId}/country/${countryCode}/pricing/filter`
    }
  })
);

export const requestCommoditiesForAssign = createAction(
  "request commodities for assign",
  () => ({
    request: {
      url: "/pricing-group/assign"
    }
  })
);

export const requestPricingColl = createAction(
  "request pricing collection",
  ({
    priceListId,
    countryCode,
    limit = 100,
    page = 1,
    commodity,
    matrix,
    ...rest
  }) => {
    const params = qs.stringify(
      { limit, page, commodity, matrix, ...rest },
      {
        arrayFormat: "bracket"
      }
    );
    return {
      request: {
        url: `/pricing-list/${priceListId}/country/${countryCode}/pricing?${params}`
      }
    };
  }
);

export const requestPricingKPI = createAction(
  "request pricing KPI",
  ({ priceListId, countryCode }, opts) => {
    const params = qs.stringify(opts, {
      arrayFormat: "bracket"
    });
    return {
      request: {
        url: `/pricing-list/${priceListId}/country/${countryCode}/pricing/kpi?${params}`
      }
    };
  }
);

export const submitColumnBatchUpdate = createAction(
  "submit column bulk edit",
  ({ priceListId, countryCode, value, x, y }) => ({
    request: {
      method: "put",
      url: `/pricing-list/${priceListId}/country/${countryCode}/pricing/batch`,
      data: { value: Number(value), x, y }
    }
  }),
  ({ countryCode, priceListId, page }) => ({ countryCode, priceListId, page })
);

export const submitLimitBatchUpdate = createAction(
  "submit limit batch update",
  ({ countryCode, limitId, limitIdFilter }) => ({
    request: {
      method: "put",
      url: `/limit/${countryCode}${limitIdFilter ? `?limitId=${limitIdFilter}` : ''}`,
      data: { limit_id: limitId }
    }
  }),
  countryCode => countryCode
);

export const submitCommoditiesMatrixBatchUpdate = createAction(
  "submit limit batch update",
  ({ countryCode, commoditiesId, matrixId }) => {
    const params = commoditiesId
      ? qs.stringify(
          { commodity: commoditiesId },
          {
            arrayFormat: "bracket"
          }
        )
      : "";
    return {
      request: {
        method: "put",
        url: `/matrix/${countryCode}?${params}`,
        data: { configuration_matrix_id: matrixId }
      }
    };
  },
  ({ priceListId, countryCode, params, success }) => ({
    priceListId,
    countryCode,
    params,
    success,
  })
);

export const updateMatrix = createAction(
  "update matrix",
  ({ configuration }) => {

    // sync configuration matrix width/height with real matrix w/h
    configuration.width = configuration.matrix?.[0].length;
    configuration.height = configuration.matrix?.length;

    configuration.leading_values = configuration.leading_values || {}

    // if leading values are out of matrix's bound, delete them
    if (
      configuration?.matrix?.length < configuration.leading_values[1]?.y ||
      configuration?.matrix?.[0]?.length < configuration.leading_values[1]?.x
    ) {
      delete configuration.leading_values[1]
    }
    if (
      configuration?.matrix?.length < configuration.leading_values[2]?.y ||
      configuration?.matrix?.[0]?.length < configuration.leading_values[2]?.x
    ) {
      delete configuration.leading_values[2]
    }

    // if leading value 2 is set and leading value 1 is not, switch them
    if (!configuration.leading_values[1]?.x && configuration.leading_values[2]?.x) {
      configuration.leading_values[1] = configuration.leading_values?.[2]
      delete configuration.leading_values[2]
    }

    return {
      request: {
        method: configuration.isDraft ? "post" : "patch",
        url: `/configuration-matrix${configuration.isDraft ? '' : `/${configuration.id}`}`,
        data: configuration,
      }
    };
  },
  ({ countryCode, configuration }) => ({ countryCode, id: configuration.id }),
);

export const deleteMatrix = createAction(
  "delete matrix",
  ({ id }) => {
    return {
      request: {
        method: "delete",
        url: `/configuration-matrix/${id}`,
      }
    };
  },
  ({ countryCode }) => ({ countryCode }),
);

export const requestCountrySettings = createAction(
  "request country settings",
  ({ countryCode }) => {
    return {
      request: {
        url: `/country-settings/${countryCode}`
      }
    };
  },
  ({ countryCode }) => ({ countryCode }),
);

export const updateCountrySettings = createAction(
  "update country settings",
  ({ countryCode, E, K }) => {
    return {
      request: {
        method: "put",
        url: `/country-settings/${countryCode}`,
        data: {
          E,
          K,
        }
      }
    }
  },
  ({ countryCode }) => ({ countryCode }),
)
// export const batchUpdateMatrices = createAction(
//   "update matrices in batch",
//   ({ countryCode, leadingValues, itemsForUpdate, drafts }) => {
//     const matrix = itemsForUpdate[0]?.matrix || drafts[0].matrix;
//     const width = matrix[0].length;
//     const height = matrix.length;
//     return {
//       request: {
//         method: "put",
//         url: `/configuration-matrix/${countryCode}/batch`,
//         data: {
//           items: itemsForUpdate.concat(drafts),
//           leading_values: Object.entries(leadingValues).reduce(
//             (acc, [id, { x, y }]) => {
//               if (x <= width && y <= height) {
//                 acc[id] = { x, y };
//               }
//               return acc;
//             },
//             {}
//           ),
//           width: width,
//           height: height
//         }
//       }
//     };
//   },
//   countryCode => countryCode
// );

export const getExchangeRates = createAction(
  "request country exchange rates for time period",
  ({ finished, id, countryCode } = {}) => {
    let params = "";
    if (finished) {
      params = qs.stringify({ pricelistId: id, countryCode });
    }
    return {
      request: {
        url: `/exchange-rate?${params}`
      }
    };
  }
);

export const updateExchangeRate = createAction(
  "update exchange rate",
  ({ currency, rate, type }) => ({
    request: {
      method: "put",
      url: "/exchange-rate",
      data: { currency, rate, type }
    }
  })
);

export const prepareDownloads = createAction(
  "prepare downloads",
  ({ pricingListId }) => ({
    request: {
      url: `/pricing-list/${pricingListId}/pricing/export/prepare`
    }
  })
);

export const keboolaImport = createAction("import data from Keboola", () => ({
  request: {
    url: "/keboola",
    method: "post"
  }
}));

export const recountKPI = createAction(
  "recount KPI",
  priceListId => ({
    request: {
      url: `/price-list/${priceListId}/recount`,
      method: "put"
    }
  }),
  priceListId => ({ priceListId })
);
