import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as s from "./Select.module.css";
import { ClassNameType } from "../types";

import VirtualizedSelect from "react-virtualized-select";
import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";
import "./VirtualizedSelect.css";
import { ReadOnlyContext } from "../../../App";

const OptionType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  label: PropTypes.string // for virtualized
});

Select.propTypes = {
  onChange: PropTypes.func,
  className: ClassNameType,
  options: PropTypes.arrayOf(OptionType),
  hasError: PropTypes.bool,
  noContainer: PropTypes.bool,
  virtualized: PropTypes.bool
};

Select.defaultProps = {
  options: []
};

export const NO_VALUE = (Select.NO_VALUE = "N/A");

export default function Select({
  onChange,
  options,
  className,
  containerClass,
  hasError,
  noContainer,
  value,
  virtualized,
  disabled,
  ...pass
}) {
  if (!Array.isArray(className)) {
    className = [className];
  }

  const handleChange = onChange;

  if (virtualized) {
    noContainer = true;
  }

  const select = virtualized ? (
    <VirtualizedSelect
      value={value}
      options={options}
      onChange={handleChange}
      className={classNames(...className)}
      disabled={disabled}
      {...pass}
    />
  ) : (
    <select
      className={classNames(s.select, ...className)}
      onChange={handleChange}
      value={value || ""}
      disabled={disabled}
      {...pass}
    >
      {/*{!value && !options.find(({ value }) => value === null) && (*/}
      {/*  <option value={null}>{NO_VALUE}</option>*/}
      {/*)}*/}
      {(options || []).map(({ value, title }) => (
        <option key={value} value={value}>
          {title}
        </option>
      ))}
    </select>
  );

  return noContainer ? (
    select
  ) : (
    <div
      className={classNames(s.container, hasError && s.error, {
        [containerClass]: containerClass
      })}
    >
      {select}
    </div>
  );
}

/**
 * Use for mutating actions.
 * If use is READER, select will be disabled.
 */
export function ActionSelect(props) {
  const readOnly = useContext(ReadOnlyContext);

  return <Select disabled={readOnly} {...props} />;
}
