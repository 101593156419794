import * as qs from "query-string";
import { getCookie } from "./auth";

export const signInUrl = () => "/sign-in";

export const pricingUrl = (priceListId, countryCode, params = {}) => {
  return `/price-list/${priceListId}/country/${countryCode}?${qs.stringify(
    params,
    {
      arrayFormat: "bracket"
    }
  )}`;
};

export const pricingUrlCurrency = (priceListId, ignore, params = {}) => {
  return `/price-list-currency/${priceListId}?${qs.stringify(
    params,
    {
      arrayFormat: "bracket"
    }
  )}`;
};

export const apiExportPricing = (
  pricingListId,
  countryCode,
  commodity = [],
  differenceParams = null
) => {
  const params = qs.stringify(
    {
      ...differenceParams,
      country_code: countryCode,
      commodity,
      "x-access-token": getCookie("access-token"),
    },
    {
      arrayFormat: "bracket"
    }
  );
  return `${process.env.REACT_APP_API_HOST}/pricing-list/${pricingListId}/pricing/export?${params}`;
};

export const apiDownloadPricing = ({ pricingListId, countryCode }) => {
  const params = qs.stringify(
    {
      country_code: countryCode,
      "x-access-token": getCookie("access-token")
    },
    {
      arrayFormat: "bracket"
    }
  );
  return `${process.env.REACT_APP_API_HOST}/pricing-list/${pricingListId}/pricing/export/download?${params}`;
};
