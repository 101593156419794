import React, { useEffect } from "react";
import * as s from "./index.module.css";
import { navigate } from "@reach/router";
import Header from "../../components/Header";
import ScreenHeadline from "../../components/ui/ScreenHeadline/ScreenHeadline";
import ColumnHeadline from "../../components/ui/ColumnHeadline/ColumnHeadline";
import Text from "../../components/ui/Text/Text";
import { Link } from "@reach/router";
import Configuration from "./Configuration";
import CountrySettings from "./CountrySettings";
import {
  requestCountries,
  requestCountrySettings,
  requestMatrixConfigurations,
} from "../../store/actions";
import { useDataRequests } from "../../library/react";

const getConfigurations = (state, countryCode) =>
  state.requests?.matrixConfigurations?.[countryCode] || {};

const mapStateToProps = (state, countryCode) => ({
  session: state.requests.session,
  countries: state.requests.countries.data,
  countrySettingsUpdateRequest: state.requests.countrySettingsUpdate,
  countriesLoading: state.requests.countries.pending > 0,
  countrySettings: state.matrixConfigurationForms?.countrySettings,
  matrixConfigurationsLoading: getConfigurations(state, countryCode).fetching,
  matrixConfigurationsForms: state.matrixConfigurationForms
});

export default function Matrix({ state, dispatch, countryCode, location }) {
  const {
    session,
    countries,
    countrySettings,
    countrySettingsUpdateRequest,
    countriesLoading,
    matrixConfigurationsLoading,
    matrixConfigurationsForms
  } = mapStateToProps(state, countryCode);

  const matrixConfigurationsLocalCopy =
    matrixConfigurationsForms.localCopy[countryCode];

  /*
   * Select first country if no one selected yet
   */
  const firstCountry = countries ? countries[0] : undefined;
  useEffect(() => {
    if (!countryCode && firstCountry) {
      navigate("/matrix/" + firstCountry.code, { replace: true });
    }
  }, [countryCode, firstCountry]);

  useDataRequests(
    { state, dispatch },
    [
      () => requestCountries(),
      () => countryCode && requestCountrySettings({ countryCode }),
      () => countryCode && requestMatrixConfigurations({ countryCode })
    ],
    [location.pathname]
  );

  const country =
    (countries || []).find(x => x.code === countryCode) || firstCountry;

  return (
    <div>
      <Header
        user={session.data?.user}
        priceLists={state.requests.priceLists.data}
      />
      <ScreenHeadline
        title="KONFIGURÁTOR MATIC"
        subtitle={country && country.name}
        loading={matrixConfigurationsLoading || countriesLoading}
      />
      <div className="p-10 flex flex-row justify-center">
        <div className="w-full max-w-xs p-2">
          <ColumnHeadline title="Stát" loading={countriesLoading} />
          {(countries || []).map(country => (
            <Link
              to={"/matrix/" + country.code}
              key={country.code}
              className="no-underline text-black"
            >
              <div
                className={`${
                  country.code === countryCode ? s.selectedCard : s.card
                } px-4 py-5 shadow my-3 cursor-pointer`}
              >
                <Text weight="600" size="0.875rem" nolink>
                  {country.name}
                </Text>
              </div>
            </Link>
          ))}
          {countries && !countries.length && (
            <div className="text-center py-6">
              <Text size="0.875rem" color="red">
                Error: Žádné státy.
              </Text>
            </div>
          )}
          {countriesLoading && (
            <div className="text-center py-6">
              <Text size="0.875rem" loading>
                Načítá se
              </Text>
            </div>
          )}
        </div>
        <div className="w-full max-w-xl p-2">
          {countryCode && (
            <>
              <ColumnHeadline
                title="Úrovně E a K"
                loading={matrixConfigurationsLoading}
              />
              <CountrySettings
                countryCode={countryCode}
                extraLevels={countrySettings?.extra_levels}
                configurations={matrixConfigurationsLocalCopy}
                requests={countrySettingsUpdateRequest}
              />
            </>
          )}
          <ColumnHeadline
            title="Matice"
            loading={matrixConfigurationsLoading}
          />
          {countryCode &&
            (matrixConfigurationsLocalCopy?.length === 0 ||
              matrixConfigurationsLocalCopy?.[0]?.country_code ===
                countryCode) && (
              <Configuration
                key={countryCode}
                configurations={matrixConfigurationsLocalCopy}
                configurationErrors={matrixConfigurationsForms.errors[countryCode]}
                countryCode={countryCode}
              />
            )}
        </div>
      </div>
    </div>
  );
}
