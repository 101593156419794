import { submitNewLimit, updateLimitFormValue } from "../actions";
import { createReducer } from "redux-act";
import { success } from "redux-saga-requests";

export const initialState = {
  title: "",
  values: []
};

export default createReducer(
  {
    [updateLimitFormValue]: (state, { kind, value }) => ({
      ...state,
      [kind]: value
    }),
    [success(submitNewLimit)]: () => initialState
  },
  initialState
);
