import {
  initializeApplication,
  setServerError,
  setAccessToken,
  ping
} from "../actions";
import { createReducer } from "redux-act";
import { combineReducers } from "redux";
import modalReducer from "./modal";
import requestsReducers from "./requests";
import priceListFormReducer from "./price-list-form";
import limitFormReducer from "./limit-form";
import matrixConfigurationFormsReducer from "./matrix-configuration-forms";
import pricingGroupsReducer from "./pricing-groups";
import pricing from "./pricing";

export const initialState = {
  "access-token": null,
  serverError: null,
  initialized: false,
  pings: 0
};

const appReducer = createReducer(
  {
    [ping]: state => state,
    [initializeApplication]: state => ({ ...state, initialized: true }),
    [setServerError]: (state, payload) => ({ ...state, serverError: payload }),
    [setAccessToken]: (state, token) => ({ ...state, "access-token": token })
  },
  initialState
);

export default combineReducers({
  requests: requestsReducers, // needs to be on the top
  app: appReducer,
  modal: modalReducer,
  priceListForm: priceListFormReducer,
  limitForm: limitFormReducer,
  matrixConfigurationForms: matrixConfigurationFormsReducer,
  pricingGroups: pricingGroupsReducer,
  pricing: pricing
});
