import axios, { CancelToken } from "axios";
import { getCookie } from "./auth";

export function fetch({ method, url }, payload = {}) {
  const source = CancelToken.source();
  const cancelMsg = "Query was cancelled by React Query";

  const promise = new Promise(async (resolve, reject) => {
    let response;
    try {
      response = await axios({
        baseURL: process.env.REACT_APP_API_HOST,
        timeout: 50_000,
        cancelToken: source.token,
        method,
        url,
        data: payload,
        headers: {
          common: { "x-access-token": getCookie("access-token") }
        }
      });
      resolve(response);
    } catch (e) {
      reject(e);
    }
  });

  promise.cancel = () => {
    source.cancel(cancelMsg);
  };

  return promise;
}
