import React, { useState } from "react";
import {
  closeModal,
  submitCommoditiesMatrixBatchUpdate
} from "../../store/actions";
import * as s from "./Modal.module.css";
import Text from "../ui/Text/Text";
import Button, { ActionButton } from "../ui/Button/Button";
import Select from "../ui/Select/Select";
import { isEmpty } from "../../library/object";

export function CommoditiesMatrixBatchUpdate({ state: { modal }, dispatch }) {
  const {
    countryCode,
    commoditiesId,
    matrices,
    countryName,
    priceListId,
    params,
    success,
  } = modal.props;
  const [inProgress, setInProgress] = useState(false);
  const [selectedMatrix, setSelectedMatrix] = useState(null);
  const isFiltered = !isEmpty(params);

  const handleSubmit = event => {
    if (event) event.preventDefault();
    setInProgress(true);
    dispatch(
      submitCommoditiesMatrixBatchUpdate({
        countryCode,
        matrixId: selectedMatrix,
        commoditiesId: isFiltered && commoditiesId,
        priceListId,
        params,
        success,
      })
    );
  };

  return (
    <div>
      <div className={`flex flex-row items-center ${s.header}`}>
        <div className="flex-1">
          <Text size="1.25rem">Nastavit hromadně matici</Text>
        </div>
        <Button
          width="10rem"
          short
          secondary
          onClick={() => {
            dispatch(closeModal());
          }}
        >
          Zavřít
        </Button>
      </div>
      <form onSubmit={handleSubmit} className={s.content}>
        {isFiltered && (
          <div className="mb-8">
            <Text>
              Nastavit matici pro <strong>{commoditiesId.length}</strong>{" "}
              vybraných ceníkových skupin.
            </Text>
          </div>
        )}
        <div className="w-full flex mb-4">
          <Select
            value={selectedMatrix}
            options={matrices}
            onChange={e => setSelectedMatrix(e.target.value)}
          />
        </div>
        <div className="py-4">
          <ActionButton
            type="submit"
            disabled={inProgress}
            className={inProgress ? "inProgress" : ""}
          >
            {inProgress
              ? "Zpracovávám"
              : isFiltered
              ? `Nastavit pro vybrané`
              : `Nastavit pro ${countryName}`}
          </ActionButton>
        </div>
      </form>
    </div>
  );
}
