import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as s from './Input.module.css'
import { ClassNameType } from '../types'

Input.propTypes = {
  onChange: PropTypes.func,
  className: ClassNameType,
  type: PropTypes.oneOf(["text", "number", "date", "search"]),
  hasError: PropTypes.bool,
  noContainer: PropTypes.bool
};

Input.defaultProps = {
  type: "text"
};

export default function Input({
  onChange,
  children,
  className,
  hasError,
  noContainer,
  icon,
  ...pass
}) {
  if (!Array.isArray(className)) {
    className = [className];
  }

  const input = (
    <input
      className={classNames(s.input, ...className, { [s.hasIcon]: icon })}
      onChange={onChange}
      {...pass}
    />
  );

  return noContainer ? (
    input
  ) : (
    <div className={classNames(s.container, hasError && s.error)}>
      {icon}
      {input}
    </div>
  );
}
