import {
  changeNomenclaturesForPricingGroupSearch,
  changeUnassignedNomenclaturesSearch,
  requestCommoditiesForAssign,
  rewriteAutoAssignSuggestion,
  selectCommodityForAutoAssign,
  toggleAutoAssignSuggestionIgnoreFlag,
} from "../actions";
import { createReducer } from "redux-act";
import { success } from "redux-saga-requests";

export const initialState = {
  selectedCommodity: undefined,
  nomenclaturesForPricingGroupSearch: "",
  unassignedNomenclaturesSearch: "",
  autoAssign: [
    /* { nomenclature_id, pricing_group_id} */
  ]
};

export default createReducer(
  {
    [selectCommodityForAutoAssign]: (state, { commodityCode }) => ({
      ...state,
      selectedCommodity: commodityCode
    }),
    [changeUnassignedNomenclaturesSearch]: (state, { search }) => ({
      ...state,
      unassignedNomenclaturesSearch: search
    }),
    [changeNomenclaturesForPricingGroupSearch]: (state, { search }) => ({
      ...state,
      nomenclaturesForPricingGroupSearch: search
    }),
    [success(requestCommoditiesForAssign)]: (state, { data }) => ({
      ...state,
      selectedCommodity: data.length ? data[0].commodity_code : null
    }),
    [rewriteAutoAssignSuggestion]: (state, { index, value }) => ({
      ...state,
      autoAssign: state.autoAssign.map((v, i) => (i === index ? value : v))
    }),
    [toggleAutoAssignSuggestionIgnoreFlag]: (state, index) => ({
      ...state,
      autoAssign: state.autoAssign.map((value, i) => ({
        ...value,
        ignore: index === i ? !value.ignore : value.ignore
      }))
    }),
  },
  initialState
);
