import {
  assignLimitToPricingGroup,
  createNewPricingGroup,
  updatePricingGroup,
  getExchangeRates,
  logout,
  requestCommodities,
  requestCommoditiesForAssign,
  requestCountries,
  requestLimitTypes,
  requestMatrixConfigurations,
  requestNomenclaturesForPricingGroup,
  requestPriceListDetail,
  requestPriceLists,
  requestPricingKPI,
  requestPricingGroupLimits,
  requestPricingGroups,
  requestSession,
  requestFilters,
  updateCountrySettings,
  requestSignIn,
  requestUnassignedNomenclatures,
  submitNewLimit,
  submitNewPriceList,
  prepareDownloads,
  patchPriceList
} from "../actions";
import { requestsReducer, success } from "redux-saga-requests";
import { combineReducers } from "redux";
import { requestsMappedByMetaReducer } from "./helpers";

const extraPricingGroupLimitReducer = (state, action) => {
  switch (action.type) {
    case success(assignLimitToPricingGroup): {
      const { countryCode, groupId, limitId } = action.meta;

      return {
        ...state,
        [countryCode]: {
          ...state[countryCode],
          data: state[countryCode].data.map(group =>
            group.id === groupId
              ? {
                  ...group,
                  pricing_group_setting: { limit_id: Number(limitId) }
                }
              : group
          )
        }
      };
    }
    default:
      return state;
  }
};

export default combineReducers(
  {
    session: requestsReducer({
      actionType: requestSession,
      resetOn: [logout]
    }),
    priceLists: requestsReducer({
      actionType: requestPriceLists,
      mutations: {
        [prepareDownloads]: state => state.data,
        [patchPriceList]: state => state.data
      }
    }),
    signIn: requestsReducer({ actionType: requestSignIn }),
    newPriceList: requestsReducer({ actionType: submitNewPriceList }),
    newLimit: requestsReducer({ actionType: submitNewLimit }),
    priceListsById: requestsMappedByMetaReducer({
      actionType: requestPriceListDetail,
      metaKeyProp: "id"
    }),
    countries: requestsReducer({
      actionType: requestCountries
      // multiple: true // seems like it's better to use null instead of empty array (easier to do loading indicators)
    }),
    matrixConfigurations: requestsMappedByMetaReducer({
      actionType: requestMatrixConfigurations,
      multiple: true,
      metaKeyProp: "countryCode"
    }),
    pricingGroupLimits: requestsMappedByMetaReducer({
      actionType: requestPricingGroupLimits,
      multiple: true,
      metaKeyProp: "countryCode",
      extraReducer: extraPricingGroupLimitReducer
    }),
    limitTypes: requestsReducer({
      actionType: requestLimitTypes,
      // multiple: true, // we do not use [] as default value and that is how we know the data was not requested yet
      resetOn: [success(submitNewLimit)] // simple solutions but produces flickering in limits table
    }),
    pricingGroups: requestsReducer({
      actionType: requestPricingGroups,
      multiple: true
    }),
    nomenclaturesForPricingGroup: requestsReducer({
      actionType: requestNomenclaturesForPricingGroup,
      multiple: true
    }),
    unassignedNomenclatures: requestsReducer({
      actionType: requestUnassignedNomenclatures,
      multiple: true
    }),
    newPricingGroup: requestsReducer({ actionType: createNewPricingGroup }),
    updatePricingGroup: requestsReducer({ actionType: updatePricingGroup }),
    commodities: requestsReducer({
      actionType: requestCommodities
    }),
    pricingKPI: requestsReducer({
      actionType: requestPricingKPI
    }),
    commoditiesForAssign: requestsReducer({
      actionType: requestCommoditiesForAssign
    }),
    filters: requestsReducer({
      actionType: requestFilters
    }),
    countrySettingsUpdate: requestsReducer({
      actionType: updateCountrySettings
    }),
    exchangeRates: requestsReducer({
      actionType: getExchangeRates,
      onSuccess: (state, action, config) => {
        const { pending } = state;
        const { data } = action.payload;
        return {
          ...state,
          pending: pending - 1,
          data: {
            ...data,
            saleRatesByCurrency: data.sales,
            purchaseRatesByCurrency: data.purchase
          }
        };
      }
    })
  },
  {
    session: null,
    signIn: null,
    newPriceList: null,
    countries: []
  }
);
